import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/leagueCoin.png'
import _imports_1 from '@/assets/strokeGrey.png'


const _hoisted_1 = { class: "boost-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "boost-info-container" }
const _hoisted_4 = { class: "boost-info" }
const _hoisted_5 = { class: "boost-name" }
const _hoisted_6 = { class: "boost-effect" }
const _hoisted_7 = { class: "price-container" }
const _hoisted_8 = {
  key: 0,
  class: "coin",
  src: _imports_0,
  alt: "Coin"
}
const _hoisted_9 = { class: "boost-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "boost",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "boost-image",
        src: _ctx.getBoostImage,
        alt: "Boost"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(_ctx.localizedBoostNameWithStat), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.localizedBoostEffect), 1),
          _createElementVNode("div", _hoisted_7, [
            (!_ctx.isMaxLevel)
              ? (_openBlock(), _createElementBlock("img", _hoisted_8))
              : _createCommentVNode("", true),
            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.priceText), 1)
          ])
        ]),
        _cache[1] || (_cache[1] = _createElementVNode("img", {
          class: "stroke-img",
          src: _imports_1,
          alt: "stroke"
        }, null, -1))
      ])
    ])
  ]))
}