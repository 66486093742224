import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

import { ref, computed } from 'vue';
import axios from 'axios';
import PlaceCard from './PlaceCard.vue';
import MakeCheckIn from './MakeCheckIn.vue';
import router from '@/router';

interface Place {
  distance: any;
  id: string;
  name: string;
  category: string;
  latitude: number;
  longitude: number;
  phoneNumber: string;
  url: string;
  address: string;
  checkins: number; // Добавьте это поле
}

const elementWidth = 163; // ширина одного элемента
const gap = 12; // расстояние между элементами


export default /*@__PURE__*/_defineComponent({
  __name: 'ScrollPlace',
  props: {
    places: {},
    timeLeft: {},
    loading: { type: Boolean }
  },
  setup(__props: any) {

const props = __props;

const containerWidth = computed(() => {
  return `${props.places.length * (elementWidth + gap) - gap}px`;
});


const getImageSrc = (category: string) => {
  return require(`@/assets/${category.toLowerCase()}.png`);
};

const selectedPlace = ref<Place | null>(null);
const loading = ref(false);
const showResult = ref(false);
const result = ref({
  streak: 0,
  coins: 0,
  bonusCoins: 0,
  leagueMultiplier: 0,
  streakBonus: 0,
  reviewBonus: 0,
  totalMultiplier: 0,
  firstCheckinBonus: 0,
  placeCheckinBonus: 0,
  boost: 0,
  isMayor: false,
  checkinsCount: 0,
  isNewMayor: false,
  previousMayorNickname: '',
  currentMayorNickname: '',
});
const selectedRate = ref<number | null>(null);
const message = ref('');

const openModal = (place: Place) => {
  selectedPlace.value = place;
  showResult.value = false;
  selectedRate.value = null;
  result.value = {
    streak: 0,
    coins: 0,
    bonusCoins: 0,
    leagueMultiplier: 0,
    streakBonus: 0,
    reviewBonus: 0,
    totalMultiplier: 0,
    firstCheckinBonus: 0,
    placeCheckinBonus: 0,
    boost: 0,
    isMayor: false,
    checkinsCount: 0,
    isNewMayor: false,
    previousMayorNickname: '',
    currentMayorNickname: '',
  };
};

const closeModal = () => {
  selectedPlace.value = null;
};

const ratePlace = (rate: number) => {
  selectedRate.value = rate;
};

const checkIn = async () => {
  if (selectedPlace.value) {
    const telegramId = localStorage.getItem('telegram_id');
    if (!telegramId) {
      console.error('Telegram ID not found in local storage');
      return;
    }

    loading.value = true;
    message.value = '';

    try {
      const response = await axios.post('/checkin', {
        telegram_id: telegramId,
        place_name: selectedPlace.value.name,
        category: selectedPlace.value.category,
        latitude: selectedPlace.value.latitude,
        longitude: selectedPlace.value.longitude,
        rate: selectedRate.value || undefined,
        address: selectedPlace.value.address
      });

      const { streak, coins, bonusCoins, leagueMultiplier, streakBonus, reviewBonus, totalMultiplier, firstCheckinBonus, placeCheckinBonus, boost, isMayor, checkinsCount, isNewMayor, previousMayorNickname, currentMayorNickname } = response.data;

      result.value = {
        streak,
        coins,
        bonusCoins,
        leagueMultiplier,
        streakBonus,
        reviewBonus,
        totalMultiplier,
        firstCheckinBonus,
        placeCheckinBonus,
        boost,
        isMayor,
        checkinsCount,
        isNewMayor,
        previousMayorNickname,
        currentMayorNickname,
      };

      console.log('Result:', result.value);

      showResult.value = true;
      message.value = 'Check-in successful';
    } catch (error) {
      console.error('Error during check-in:', error);
      message.value = 'Check-in failed';
    } finally {
      loading.value = false;
    }
  }
};

const returnHome = () => {
  selectedPlace.value = null;
  `router.push({ name: 'Main' }).then(() => {
    window.location.reload();
  });`
  window.location.reload();
};


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      style: _normalizeStyle({ width: containerWidth.value }),
      ref: "scrollContainer",
      class: "scroll-container"
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.places, (item) => {
        return (_openBlock(), _createBlock(PlaceCard, {
          key: item.id,
          imageSrc: getImageSrc(item.category),
          name: item.name,
          address: item.address,
          checkins: item.checkins,
          onCheckIn: ($event: any) => (openModal(item)),
          timeLeft: _ctx.timeLeft,
          loading: loading.value,
          distance: item.distance
        }, null, 8, ["imageSrc", "name", "address", "checkins", "onCheckIn", "timeLeft", "loading", "distance"]))
      }), 128))
    ], 4),
    (selectedPlace.value)
      ? (_openBlock(), _createBlock(MakeCheckIn, {
          key: 0,
          selectedPlace: selectedPlace.value,
          loading: loading.value,
          showResult: showResult.value,
          result: result.value,
          selectedRate: selectedRate.value,
          closeModal: closeModal,
          ratePlace: ratePlace,
          checkIn: checkIn,
          returnHome: returnHome,
          message: message.value
        }, null, 8, ["selectedPlace", "loading", "showResult", "result", "selectedRate", "message"]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})