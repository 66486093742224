<template>
  <div>
    <div v-if="placesStore.sortedPlaces" class="container">
      <keep-alive>
        <div id="map"></div>
      </keep-alive>
      <div class="places-container">
        <div class="places" v-for="place in placesStore.sortedPlaces" :key="place.id">
          <div class="place-container">
            <div class="place-image" :style="`background-image: url(${placesStore.getImageSrc(place.category)})`"></div>
            <div class="place-info">
              <div class="last-checkin">{{ place.last_checkin }}</div>
              <div class="place-name">{{ place.name }}</div>
              <div class="place-address">{{ place.address }}</div>
              <div class="place-checkins">{{ place.count }} {{ $t('check_ins') }}</div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="container">
      <div class="make-checkin-container">
        <img class="make-checkin-img" :src="require('@/assets/make-checkin.png')">
        <p class="make-checkin-text">{{ $t('make_checkins') }}</p>
      </div>
      <button class="make-checkin-button" @click="goCheckin">Check-in</button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { usePlacesStore } from '../store/placesStore';

const router = useRouter();
const placesStore = usePlacesStore();
const MAP_ELEMENT_ID = 'map';

onMounted(async () => {
  window.Telegram.WebApp.ready();

  // Показываем кнопку "Назад"
  window.Telegram.WebApp.BackButton.show();

  // Обрабатываем клик по кнопке "Назад"
  window.Telegram.WebApp.BackButton.onClick(() => {
    router.push('/discovery');
  });

  const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  if (!telegramId) {
    console.error('Не удалось получить telegram ID');
    return;
  }

  try {
    // Получаем токен для карты
    const token = await placesStore.fetchMapKitToken();
  
    // Инициализируем или переиспользуем карту
    placesStore.initMap(token, MAP_ELEMENT_ID);
    
    // Настраиваем взаимодействие с картой
    placesStore.setupMapInteractions(MAP_ELEMENT_ID);

    placesStore.fetchPlaces(telegramId);
  } catch (error) {
    console.error('Ошибка при инициализации карты:', error);
  }
});

const goCheckin = () => {
  router.push('Discovery')
};
</script>

<style scoped>

.make-checkin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: calc(100vh - 190px);
}

.make-checkin-text {
  color: #FFFFFF;
  font-size: 22px;
  font-weight: 700;
}

.make-checkin-button {
  background: #007BFF;
  color: white;
  border-radius: 10px;
  padding: 14px 134px;
  font-size: 17px;
  font-weight: 590;
  border: none;
}

.make-checkin-img {
  width: 144px;
}
.place-address {
  color: var(--Native-subtitle_text_color, #AAAAAA);
  font-size: 13px;
  font-weight: 400;
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

hr {
  width: 100%;
  border: 0.5px solid var(--Custom-divider, #FFFFFF0D);
  margin-bottom: 0;
}

.place-checkins {
  margin-top: 8px;
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-hint_color, #AAAAAA);
}

.place-image {
  width: 40px;
  height: 40px;
  background-size: cover;
  background-position: 50%;
  margin-left: 16px;
  margin-bottom: 10px;
}

.place-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
}

.place-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
}

.last-checkin {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-subtitle_text_color, #AAAAAA);
}

#map {
    width: 90%;
    height: 250px;
    border-radius: 15px; /* Скругленные углы */
    overflow: hidden;
    touch-action: none; /* Отключаем жесты прокрутки */
}

.places{
  width: 98%;
  display: flex;
  flex-direction: column;
  color: #fff;
  height: 65px;
  /* margin-bottom: 12px; */
  border-radius: 10px;
  align-items: flex-start;
  justify-content: center;
  padding-top: 10px;
  height: auto;
}

.places-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 95px;
  justify-content: center;
  width: 90%;
  background: var(--Native-bg_color, #212121);
  border-radius: 12px;
  height: auto;
}

.place-name {
    font-size: 18px;
    font-weight: 510;
}

.place-category {
    margin-left: 15px;
    font-size: 12px;
    font-weight: 600;
}
</style>
