<template>
  <div class="container">
    <header>
      <div class="title-container">
        <img class="friends-icon" src="@/assets/friends.png" alt="Friends" />
        <p class="title">{{ $t('frens') }}</p>
        <p class="description">{{ $t('cashback') }}</p>
      </div>
    </header>

    <div class="buttons">
      <div class="claim">
        <div class="claim-coins-container">
          <img class="coin" src="@/assets/coin.png" alt="Coin" />
          <p class="claim-coins">{{ friendsStore.formattedRefBonus }}</p>
        </div>  
        <button class="claim-button" @click="claim">{{ $t('claim') }}</button>
      </div> 
      <button class="invite" @click="showModal = true">
        <img class="invite-icon" src="@/assets/plusCircle.png" alt="Invite" />
        {{ $t('invite') }}
      </button>
    </div>

    <div v-if="friendsStore.referrals.length > 0" class="refferals">
      <p class="refferals-title">{{ $t('my_frens') }}</p>
      <RefferalComponent 
        v-for="referral in friendsStore.referrals"
        :photo="referral.photo"
        :key="referral.telegram_id" 
        :name="referral.username" 
        :coins="formatNumber(referral.coins)" 
        :league="referral.league_name"
        :rank="referral.rank"
      />
      <div class="refferal-empty" v-if="friendsStore.referrals.length > 0">
      </div>
    </div>
  </div>
  <ModalComponent :show="showModal" @close="showModal = false" @share="shareReferral" @copy="copyReferral"/>
</template> 

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useFriendsStore } from '../store/friendsStore';
import RefferalComponent from '../components/RefferalComponent.vue';
import ModalComponent from '../components/ModalComponent.vue';

const router = useRouter();
const showModal = ref(false);
const friendsStore = useFriendsStore();

function formatNumber(number: number): string {
  if (typeof number !== 'number') {
    console.error('formatNumber получил не числовое значение:', number);
    return String(number);
  }
  return number.toLocaleString('en-US');
}

const shareReferral = (): void => {
  console.log('Отправка реферальной ссылки');
  showModal.value = false;
};

const copyReferral = (): void => {
  console.log('Копирование реферальной ссылки');
  showModal.value = false;
};

onMounted(async (): Promise<void> => {
  window.Telegram.WebApp.ready();
  
  // Показываем кнопку "Назад"
  window.Telegram.WebApp.BackButton.show();
  
  // Обрабатываем клик по кнопке "Назад"
  window.Telegram.WebApp.BackButton.onClick(() => {
    router.push('/discovery');  
  });
});

const claim = async (): Promise<void> => {
  const success = await friendsStore.claimBonus();
  if (success) {
    console.log('Бонусы успешно получены');
  }
};

// Для отладки, чтобы убедиться, что showModal меняется
watch(showModal, (newValue: boolean) => {
  console.log('showModal изменился на:', newValue);
});
</script>

<style scoped>

.invite-icon {
  width: 24px;
  height: 24px;
}

.refferals-title {
  margin: 16px auto 8px 16px;
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-section_header_text_color, #AAAAAA);
}

.coin {
  width: 27px;
  height: 27px;
}

.refferal-empty {
  height: 91px;
  background-color: #0F0F0F;
  width: 100%;
}

.claim-button {
  background: var(--Custom-secondary_fill, #2990ff26);
  width: 93px;
  height: 42px;
  padding: 12px 14px 12px 14px;
  gap: 8px;
  border-radius: 12px;
  color: #2990ff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 590;
  border: none;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: calc(100dvh + 1px);  
  overflow: hidden;
}

.container::-webkit-scrollbar {
  display: none; /* для Chrome, Safari и Opera */
}

.claim {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 32px;
  padding: 8px;
  border: 1px solid var(--Custom-divider, #FFFFFF0D);
  border-radius: 19px;
  margin-bottom: 16px;
}

.claim-coins-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  width: 121px;
}

.claim-coins {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
}

.description {
  max-width: 248px;
  white-space: normal;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  color: var(--Native-hint_color, #AAAAAA);
}

.title {
  font-size: 34px;
  font-weight: 400;
}

.friends-icon {
  width: 94px;
  height: 94px;
}

.title-container {
  gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.invite {
  width: 270px;
  height: 54px;
  padding: 15px 12px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 590;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;
}

.invite:hover {
  background: #0056b3;
}

.go-back {
  position: absolute;
  left: 0;
  width: 30px;
  margin-left: 10px;
}

header {
  width: 390px;
  height: auto;
  padding: 40px 60px 40px 60px;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

p, h1, .refferal-points {
  margin: 0;
}

p {
  font-size: 20px;
  color: aliceblue;
  margin-bottom: 10px;
  font-weight: 600;
}

.refferals {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: auto;
  border-radius: 12px;
  background: var(--Native-bg_color, #212121);
  margin-top: auto;
}

</style>