<template> 
    <div class="place-card">
      <div class="background-image" :style="{ backgroundImage: `url(${imageSrc})` }"></div>
      <div class="foreground-content">
        <div class="image-container">
          <img :src="imageSrc" alt="Place Image" class="place-image" />
        </div>
        <div class="place-info-container">
          <div class="place-info">
            <div class="place-title">{{ name }}</div>
            <div class="distance">
              <img class="gps-icon" src="@/assets/gps.png" alt="GPS Icon" />
              {{ distance }}m</div>
          </div>
          <div v-if="timeLeft < 0.1" class="checkin-button" @click="$emit('checkIn')">{{ $t('check_in') }}</div>
        </div>
      </div>
    </div>
  </template>
  
<script setup lang="ts">
import { defineProps, watchEffect } from 'vue';

const props = defineProps({
  imageSrc: String,
  name: String,
  address: String,
  checkins: Number,
  timeLeft: {
    type: Number,
    required: true,
    default: 0,
  },
  loading: Boolean,
  distance: Number,
});

// Добавление логирования для проверки обновлений timeLeft
watchEffect(() => {
  console.log("timeLeft изменился: ", props.timeLeft);
});
</script>

<style scoped>

.place-info-container {
  width: 100%;
  height: 100%;
  background: rgba(30, 30, 30, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
}

.distance {
  font-size: 13px;
  font-weight: 590;
  color: rgba(255, 255, 255, 0.6);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2px;
}

@keyframes skeleton-loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.skeleton {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(128, 128, 128, 0.1) 25%, rgba(224, 224, 224, 0.1) 50%, rgba(128, 128, 128, 0.1) 75%);
  background-size: 200px 100%;
  animation: skeleton-loading 2.5s infinite;
  border-radius: 7px;
}

.gps-icon {
  width: 9.5px;
}

.checkin-button {
  width: 137px;
  height: 36px;
  background: var(--Native-button_color, #2990FF);
  border-radius: 20px;
  padding: 5px, 12px, 15px, 12px;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;
}

  .place-card {
    position: relative;
    width: 163px;
    height: auto;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 32px 64px rgba(0, 0, 0, .04), 0 0 2px 1px rgba(0, 0, 0, .02);
    background: var(--Custom-surface_primary, hsla(0, 0%, 9%, .95));
  }
  
  .place-info {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    filter: blur(20px);
    transform: scale(1.2);
    z-index: 1;
    opacity: 40%;
  }
  
  .foreground-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11px;
    color: white;
    height: 100%;
  }
  
  .image-container {
    width: 88px;
    height: 88px;
    margin-bottom: 10px;
  }
  
  .place-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .place-title {
    font-size: 20px;
    font-weight: 590;
    max-width: 99px;             
    overflow: hidden;             
    text-overflow: ellipsis;      
    display: inline-block;        
    line-height: 1.2em;           
    max-height: 2.4em;            
    white-space: normal;         
    word-wrap: break-word;  
    margin-bottom: 16px;      
}
  
  .place-address {
    font-size: 10px;
    text-align: center;
    font-weight: 400;
  }
  
  .place-checkins {
    font-size: 15px;
    color: #AAAAAA;
    margin-top: 8px;
    margin-bottom: 20px;
    font-weight: 400;
  }
  </style>
  