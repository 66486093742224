import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, createBlock as _createBlock, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/stroke.png'
import _imports_1 from '@/assets/gps.png'
import _imports_2 from '@/assets/geocheckin.png'
import _imports_3 from '@/assets/mayor/venki.png'
import _imports_4 from '@/assets/mayor/layer.png'
import _imports_5 from '@/assets/mayor/crown.png'
import _imports_6 from '@/assets/mayor/stars.png'
import _imports_7 from '@/assets/coin.png'
import _imports_8 from '@/assets/leagueCoin.png'


const _hoisted_1 = {
  key: 0,
  class: "modal-header"
}
const _hoisted_2 = { class: "foreground-content" }
const _hoisted_3 = { class: "selected-place-name" }
const _hoisted_4 = { class: "address-container" }
const _hoisted_5 = { class: "distance" }
const _hoisted_6 = { class: "selected-place-address" }
const _hoisted_7 = { class: "selected-place-category" }
const _hoisted_8 = { class: "modal-rating" }
const _hoisted_9 = { class: "modal-rating-text" }
const _hoisted_10 = { class: "rating-container" }
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = ["disabled"]
const _hoisted_16 = {
  key: 0,
  class: "loader"
}
const _hoisted_17 = { key: 1 }
const _hoisted_18 = {
  key: 1,
  class: "loading-container"
}
const _hoisted_19 = { class: "animation" }
const _hoisted_20 = { class: "foreground-content-result" }
const _hoisted_21 = { class: "result-info" }
const _hoisted_22 = { class: "address-container" }
const _hoisted_23 = {
  class: "selected-place-name",
  id: "result-name"
}
const _hoisted_24 = { class: "distance" }
const _hoisted_25 = { class: "selected-place-address" }
const _hoisted_26 = { class: "selected-place-category" }
const _hoisted_27 = { class: "checkin-info-container" }
const _hoisted_28 = { class: "checkin-info" }
const _hoisted_29 = {
  key: 0,
  class: "mayor-container"
}
const _hoisted_30 = { class: "mayor-image-container" }
const _hoisted_31 = { class: "circle-account" }
const _hoisted_32 = { class: "mayor-message" }
const _hoisted_33 = {
  key: 1,
  class: "mayor-container"
}
const _hoisted_34 = { class: "mayor-image-container-not-current" }
const _hoisted_35 = {
  class: "circle-account",
  style: { position: 'relative' }
}
const _hoisted_36 = { class: "mayor-message" }
const _hoisted_37 = { class: "bonus-coins" }
const _hoisted_38 = { class: "bonus-coins-text" }
const _hoisted_39 = { class: "message-info" }
const _hoisted_40 = {
  key: 0,
  class: "bonus-coins-container"
}
const _hoisted_41 = { class: "checkin-text" }
const _hoisted_42 = {
  key: 1,
  class: "bonus-coins-container"
}
const _hoisted_43 = { class: "checkin-text" }
const _hoisted_44 = {
  key: 2,
  class: "bonus-coins-container"
}
const _hoisted_45 = { class: "bonus-coins-container" }
const _hoisted_46 = {
  key: 3,
  class: "bonus-coins-container"
}
const _hoisted_47 = {
  key: 4,
  class: "bonus-coins-container"
}
const _hoisted_48 = {
  key: 5,
  class: "bonus-coins-container"
}
const _hoisted_49 = { class: "footer" }

import { ref, onMounted, watch, computed } from 'vue';
  import { LottieAnimation } from "lottie-web-vue"
  import ConfettiJSON from '../assets/confetti.json'
  import { getLocalizedText } from '@/localizatoin';
  import mayorNotification from './mayorNotification.vue';

  interface Place {
    id: string;
    name: string;
    category: string;
    latitude: number;
    longitude: number;
    phoneNumber: string;
    url: string;
    address: string;
    distance: any;
  }
  
  
export default /*@__PURE__*/_defineComponent({
  __name: 'MakeCheckIn',
  props: {
    selectedPlace: {},
    loading: { type: Boolean },
    showResult: { type: Boolean },
    result: {},
    selectedRate: {},
    closeModal: { type: Function },
    ratePlace: { type: Function },
    checkIn: { type: Function },
    returnHome: { type: Function },
    message: {},
    imageSrc: {}
  },
  setup(__props: any) {

  let anim = ref()  

  const usernameFirst = ref('');

  const animationContainer = ref(null);
  
  const showMayorNotification = ref(false);
  
  const props = __props;
  
  const handleCloseNotification = () => {
    showMayorNotification.value = false;
  };

  const declensionOfCheckins = (count: number): string => {
    const titles = ['чекином', 'чекинами'];
    const cases = [2, 0, 1, 1, 1, 2];
    const declension = (count % 100 > 4 && count % 100 < 20) ? 1 : cases[Math.min(count % 10, 5)];
    return titles[declension === 1 ? 1 : 0];
  };

  const checkinWord = computed(() => {
    return declensionOfCheckins(props.result.checkinsCount);
  });
  const shareToTelegramStory = () => {
  if (typeof window.Telegram !== 'undefined' && window.Telegram.WebApp) {
    const localizedText = getLocalizedText('checkinMessage', {
      placeName: props.selectedPlace.name,
      bonusCoins: props.result.bonusCoins,
    });

    window.Telegram.WebApp.shareToStory(
      'https://checkiton.net/logo/logovertical.jpg', // Замените на фактический URL вашего медиафайла
      {
        text: localizedText,
      }
    );
  } else {
    console.error('Telegram WebApp API не доступен');
  }
};

const firstLetter = computed(() => {
  const username = localStorage.getItem('username');
  return username ? username.charAt(0).toUpperCase() : '';
});

  const confettiHapticPattern = async () => {
      const haptic = window.Telegram.WebApp.HapticFeedback;
      if (haptic) {
        haptic.impactOccurred("medium");
        await new Promise(resolve => setTimeout(resolve, 100));
        haptic.impactOccurred("light");
        await new Promise(resolve => setTimeout(resolve, 50));
        haptic.impactOccurred("heavy");
        await new Promise(resolve => setTimeout(resolve, 150));
        haptic.impactOccurred("light");
      } else {
        console.warn("Haptic feedback is not supported on this device.");
      }
    };

  watch(() => props.showResult, (newValue, oldValue) => {
  console.log('watch triggered: showResult changed from', oldValue, 'to', newValue);
    if (newValue) {
      console.log('Запускаю анимацию');
      confettiHapticPattern();
      console.log(props.result);
    }
  });

  const getImageSrc = (category: string) => {
    return require(`@/assets/${category.toLowerCase()}.png`);
  };

  // Наблюдатель за изменением props.result
  watch(() => props.result, (newValue) => {
    if (newValue) {
      console.log('Result updated:', newValue);
      showMayorNotification.value = newValue.isNewMayor;
      console.log('Show Mayor Notification:', showMayorNotification.value); // Debug log
    }
  });

  watch(() => props.showResult, (newValue, oldValue) => {
    console.log('watch triggered: showResult changed from', oldValue, 'to', newValue);
    if (newValue) {
      console.log('Запускаю анимацию');
      confettiHapticPattern();
      console.log(props.result);
    }
  });

  onMounted(() => {
    const storedUsername = localStorage.getItem('username')
    usernameFirst.value = storedUsername ?? '';
    console.log('Username:', usernameFirst.value); // Debug log
  });


return (_ctx: any,_cache: any) => {
  return (_ctx.selectedPlace)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal",
        onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
      }, [
        _createElementVNode("div", {
          class: "modal-content",
          onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
        }, [
          (!_ctx.loading && !_ctx.showResult)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("div", {
                  class: "go-back",
                  onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
                }, _cache[11] || (_cache[11] = [
                  _createElementVNode("img", {
                    class: "stroke",
                    src: _imports_0,
                    alt: "Go back"
                  }, null, -1)
                ])),
                _createElementVNode("div", {
                  class: "background-image",
                  style: _normalizeStyle({ backgroundImage: `url(${getImageSrc(_ctx.selectedPlace.category)})` })
                }, null, 4),
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("div", {
                    class: "image-container",
                    style: _normalizeStyle({ backgroundImage: `url(${getImageSrc(_ctx.selectedPlace.category)})`, width: '120px', height: '120px' })
                  }, null, 4),
                  _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.selectedPlace.name), 1),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                      _cache[12] || (_cache[12] = _createElementVNode("img", {
                        class: "gps-icon",
                        src: _imports_1,
                        alt: "GPS Icon"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(_ctx.selectedPlace.distance) + "m ", 1),
                      _createElementVNode("p", _hoisted_6, _toDisplayString(_ctx.selectedPlace.address), 1)
                    ])
                  ]),
                  _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.selectedPlace.category), 1)
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('rate')), 1),
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("div", {
                      class: "like-container",
                      style: _normalizeStyle(_ctx.selectedRate === 1 ? { backgroundColor: '#dddddd3d'} : {}),
                      onClick: _cache[1] || (_cache[1] = () => _ctx.ratePlace(1))
                    }, [
                      _createElementVNode("img", {
                        src: require('@/assets/rate/fire.png'),
                        class: "rating-icon",
                        alt: "Dislike"
                      }, null, 8, _hoisted_11)
                    ], 4),
                    _createElementVNode("div", {
                      class: "dislike-container",
                      style: _normalizeStyle(_ctx.selectedRate === 2 ? { backgroundColor: '#dddddd3d'} : {}),
                      onClick: _cache[2] || (_cache[2] = () => _ctx.ratePlace(2))
                    }, [
                      _createElementVNode("img", {
                        src: require('@/assets/rate/heart.png'),
                        class: "rating-icon",
                        alt: "Like"
                      }, null, 8, _hoisted_12)
                    ], 4),
                    _createElementVNode("div", {
                      class: "dislike-container",
                      style: _normalizeStyle(_ctx.selectedRate === 3 ? { backgroundColor: '#dddddd3d'} : {}),
                      onClick: _cache[3] || (_cache[3] = () => _ctx.ratePlace(3))
                    }, [
                      _createElementVNode("img", {
                        src: require('@/assets/rate/poop.png'),
                        class: "rating-icon",
                        alt: "Like"
                      }, null, 8, _hoisted_13)
                    ], 4),
                    _createElementVNode("div", {
                      class: "dislike-container",
                      style: _normalizeStyle(_ctx.selectedRate === 4 ? { backgroundColor: '#dddddd3d'} : {}),
                      onClick: _cache[4] || (_cache[4] = () => _ctx.ratePlace(4))
                    }, [
                      _createElementVNode("img", {
                        src: require('@/assets/rate/heze.png'),
                        class: "rating-icon",
                        alt: "Like"
                      }, null, 8, _hoisted_14)
                    ], 4)
                  ])
                ]),
                _createElementVNode("button", {
                  class: "make-checkin",
                  onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.checkIn && _ctx.checkIn(...args))),
                  disabled: _ctx.loading
                }, [
                  _cache[13] || (_cache[13] = _createElementVNode("img", {
                    class: "checkin-icon",
                    src: _imports_2,
                    alt: "Checkin"
                  }, null, -1)),
                  (_ctx.loading)
                    ? (_openBlock(), _createElementBlock("span", _hoisted_16))
                    : (_openBlock(), _createElementBlock("span", _hoisted_17, _toDisplayString(_ctx.$t('check_in')), 1))
                ], 8, _hoisted_15)
              ]))
            : (_ctx.loading)
              ? (_openBlock(), _createElementBlock("div", _hoisted_18, _cache[14] || (_cache[14] = [
                  _createElementVNode("div", { class: "loader" }, null, -1)
                ])))
              : (_openBlock(), _createElementBlock("div", {
                  key: 2,
                  class: "result-container",
                  onClick: _cache[8] || (_cache[8] = _withModifiers(() => {}, ["stop"])),
                  ref_key: "animationContainer",
                  ref: animationContainer
                }, [
                  _createElementVNode("div", _hoisted_19, [
                    _createVNode(_unref(LottieAnimation), {
                      ref_key: "anim",
                      ref: anim,
                      "animation-data": _unref(ConfettiJSON),
                      loop: true,
                      "auto-play": true,
                      speed: 1,
                      onLoopComplete: _ctx.loopComplete,
                      onComplete: _ctx.complete,
                      onEnterFrame: _ctx.enterFrame,
                      onSegmentStart: _ctx.segmentStart,
                      onStopped: _ctx.stopped
                    }, null, 8, ["animation-data", "onLoopComplete", "onComplete", "onEnterFrame", "onSegmentStart", "onStopped"])
                  ]),
                  _createElementVNode("div", {
                    class: "background-image",
                    style: _normalizeStyle({ backgroundImage: `url(${getImageSrc(_ctx.selectedPlace.category)})` })
                  }, null, 4),
                  _createElementVNode("div", _hoisted_20, [
                    _createElementVNode("div", {
                      class: "image-container",
                      style: _normalizeStyle({ backgroundImage: `url(${getImageSrc(_ctx.selectedPlace.category)})`})
                    }, null, 4),
                    _createElementVNode("div", _hoisted_21, [
                      _createElementVNode("div", _hoisted_22, [
                        _createElementVNode("h2", _hoisted_23, _toDisplayString(_ctx.selectedPlace.name), 1),
                        _createElementVNode("div", _hoisted_24, [
                          _cache[15] || (_cache[15] = _createElementVNode("img", {
                            class: "gps-icon",
                            src: _imports_1,
                            alt: "GPS Icon"
                          }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.selectedPlace.distance) + "m ", 1),
                          _createElementVNode("p", _hoisted_25, _toDisplayString(_ctx.selectedPlace.address), 1)
                        ]),
                        _createElementVNode("p", _hoisted_26, _toDisplayString(_ctx.selectedPlace.category), 1)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_27, [
                    _createElementVNode("div", _hoisted_28, [
                      (_ctx.result.isMayor)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _createElementVNode("div", _hoisted_30, [
                              _cache[16] || (_cache[16] = _createElementVNode("img", {
                                class: "mayor-image",
                                src: _imports_3,
                                alt: "Mayor"
                              }, null, -1)),
                              _createElementVNode("div", _hoisted_31, _toDisplayString(firstLetter.value), 1),
                              _cache[17] || (_cache[17] = _createElementVNode("img", {
                                class: "layer",
                                src: _imports_4,
                                alt: "Layer"
                              }, null, -1)),
                              _cache[18] || (_cache[18] = _createElementVNode("img", {
                                class: "crown",
                                src: _imports_5,
                                alt: "Crown"
                              }, null, -1)),
                              _cache[19] || (_cache[19] = _createElementVNode("img", {
                                class: "stars",
                                src: _imports_6,
                                alt: "Stars"
                              }, null, -1))
                            ]),
                            _createElementVNode("p", _hoisted_32, _toDisplayString(_ctx.$t('mayorMessage', { count: _ctx.result.checkinsCount, checkinWord: checkinWord.value })), 1)
                          ]))
                        : _createCommentVNode("", true),
                      (_ctx.result.currentMayorNickname)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_33, [
                            _createElementVNode("div", _hoisted_34, [
                              _createElementVNode("div", _hoisted_35, _toDisplayString(firstLetter.value), 1),
                              _cache[20] || (_cache[20] = _createElementVNode("img", {
                                class: "crown-not-current",
                                src: _imports_5,
                                alt: "Crown"
                              }, null, -1)),
                              _createElementVNode("p", _hoisted_36, _toDisplayString(_ctx.$t('currentMayorMessage', { nickname: _ctx.result.currentMayorNickname})), 1)
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (showMayorNotification.value && _ctx.result.isNewMayor)
                        ? (_openBlock(), _createBlock(mayorNotification, {
                            key: 2,
                            isNewMayor: _ctx.result.isNewMayor,
                            previousMayorNickname: _ctx.result.previousMayorNickname,
                            currentUsername: usernameFirst.value,
                            onCloseNotification: handleCloseNotification
                          }, null, 8, ["isNewMayor", "previousMayorNickname", "currentUsername"]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_37, [
                        _createElementVNode("p", _hoisted_38, [
                          _cache[21] || (_cache[21] = _createTextVNode("+ ")),
                          _cache[22] || (_cache[22] = _createElementVNode("img", {
                            class: "coin",
                            src: _imports_7,
                            alt: "Coin"
                          }, null, -1)),
                          _createTextVNode(" " + _toDisplayString(_ctx.result.bonusCoins), 1)
                        ]),
                        _createElementVNode("p", _hoisted_39, _toDisplayString(_ctx.$t('message')), 1)
                      ]),
                      _createElementVNode("div", {
                        class: "bonus-info",
                        onClick: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["stop"]))
                      }, [
                        (_ctx.result.placeCheckinBonus !== 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_40, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('check_in')), 1),
                              _createElementVNode("div", _hoisted_41, [
                                _cache[23] || (_cache[23] = _createElementVNode("p", null, "+ ", -1)),
                                _cache[24] || (_cache[24] = _createElementVNode("img", {
                                  class: "coin",
                                  src: _imports_8,
                                  alt: "Coin"
                                }, null, -1)),
                                _createElementVNode("p", null, _toDisplayString(_ctx.result.placeCheckinBonus), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.result.firstCheckinBonus !== 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_42, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('first_checkin')), 1),
                              _createElementVNode("div", _hoisted_43, [
                                _cache[25] || (_cache[25] = _createElementVNode("p", null, "+ ", -1)),
                                _cache[26] || (_cache[26] = _createElementVNode("img", {
                                  class: "coin",
                                  src: _imports_8,
                                  alt: "Coin"
                                }, null, -1)),
                                _cache[27] || (_cache[27] = _createTextVNode()),
                                _createElementVNode("p", null, _toDisplayString(_ctx.result.firstCheckinBonus), 1)
                              ])
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.result.leagueMultiplier !== 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_44, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('league_bonus')), 1),
                              _createElementVNode("p", null, "x" + _toDisplayString(_ctx.result.leagueMultiplier + 1), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createElementVNode("div", _hoisted_45, [
                          _createElementVNode("p", null, _toDisplayString(_ctx.$t('streak')), 1),
                          _createElementVNode("p", null, _toDisplayString(_ctx.result.streak), 1)
                        ]),
                        (_ctx.result.streakBonus !== 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('streak_bonus')), 1),
                              _createElementVNode("p", null, "x" + _toDisplayString(_ctx.result.streakBonus + 1), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.result.reviewBonus !== 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_47, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('review_bonus')), 1),
                              _createElementVNode("p", null, "x" + _toDisplayString(_ctx.result.reviewBonus + 1), 1)
                            ]))
                          : _createCommentVNode("", true),
                        (_ctx.result.boost !== 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_48, [
                              _createElementVNode("p", null, _toDisplayString(_ctx.$t('traveller')), 1),
                              _createElementVNode("p", null, "x" + _toDisplayString(_ctx.result.boost + 1), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ])
                  ]),
                  _createElementVNode("div", _hoisted_49, [
                    _createElementVNode("button", {
                      class: "close-button",
                      onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (props.returnHome && props.returnHome(...args)))
                    }, _toDisplayString(_ctx.$t('close')), 1),
                    _createElementVNode("button", {
                      class: "share-button",
                      onClick: shareToTelegramStory
                    }, _toDisplayString(_ctx.$t('share_to_story')), 1)
                  ])
                ], 512))
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})