import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, unref as _unref, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/friends.png'
import _imports_1 from '@/assets/coin.png'
import _imports_2 from '@/assets/plusCircle.png'


const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "title-container" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "description" }
const _hoisted_5 = { class: "buttons" }
const _hoisted_6 = { class: "claim" }
const _hoisted_7 = { class: "claim-coins-container" }
const _hoisted_8 = { class: "claim-coins" }
const _hoisted_9 = {
  key: 0,
  class: "refferals"
}
const _hoisted_10 = { class: "refferals-title" }
const _hoisted_11 = {
  key: 0,
  class: "refferal-empty"
}

import { ref, onMounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { useFriendsStore } from '../store/friendsStore';
import RefferalComponent from '../components/RefferalComponent.vue';
import ModalComponent from '../components/ModalComponent.vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'ReferralsView',
  setup(__props) {

const router = useRouter();
const showModal = ref(false);
const friendsStore = useFriendsStore();

function formatNumber(number: number): string {
  if (typeof number !== 'number') {
    console.error('formatNumber получил не числовое значение:', number);
    return String(number);
  }
  return number.toLocaleString('en-US');
}

const shareReferral = (): void => {
  console.log('Отправка реферальной ссылки');
  showModal.value = false;
};

const copyReferral = (): void => {
  console.log('Копирование реферальной ссылки');
  showModal.value = false;
};

onMounted(async (): Promise<void> => {
  window.Telegram.WebApp.ready();
  
  // Показываем кнопку "Назад"
  window.Telegram.WebApp.BackButton.show();
  
  // Обрабатываем клик по кнопке "Назад"
  window.Telegram.WebApp.BackButton.onClick(() => {
    router.push('/discovery');  
  });
});

const claim = async (): Promise<void> => {
  const success = await friendsStore.claimBonus();
  if (success) {
    console.log('Бонусы успешно получены');
  }
};

// Для отладки, чтобы убедиться, что showModal меняется
watch(showModal, (newValue: boolean) => {
  console.log('showModal изменился на:', newValue);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("header", null, [
        _createElementVNode("div", _hoisted_2, [
          _cache[2] || (_cache[2] = _createElementVNode("img", {
            class: "friends-icon",
            src: _imports_0,
            alt: "Friends"
          }, null, -1)),
          _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('frens')), 1),
          _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.$t('cashback')), 1)
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _cache[3] || (_cache[3] = _createElementVNode("img", {
              class: "coin",
              src: _imports_1,
              alt: "Coin"
            }, null, -1)),
            _createElementVNode("p", _hoisted_8, _toDisplayString(_unref(friendsStore).formattedRefBonus), 1)
          ]),
          _createElementVNode("button", {
            class: "claim-button",
            onClick: claim
          }, _toDisplayString(_ctx.$t('claim')), 1)
        ]),
        _createElementVNode("button", {
          class: "invite",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (showModal.value = true))
        }, [
          _cache[4] || (_cache[4] = _createElementVNode("img", {
            class: "invite-icon",
            src: _imports_2,
            alt: "Invite"
          }, null, -1)),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('invite')), 1)
        ])
      ]),
      (_unref(friendsStore).referrals.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
            _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.$t('my_frens')), 1),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(friendsStore).referrals, (referral) => {
              return (_openBlock(), _createBlock(RefferalComponent, {
                photo: referral.photo,
                key: referral.telegram_id,
                name: referral.username,
                coins: formatNumber(referral.coins),
                league: referral.league_name,
                rank: referral.rank
              }, null, 8, ["photo", "name", "coins", "league", "rank"]))
            }), 128)),
            (_unref(friendsStore).referrals.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11))
              : _createCommentVNode("", true)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(ModalComponent, {
      show: showModal.value,
      onClose: _cache[1] || (_cache[1] = ($event: any) => (showModal.value = false)),
      onShare: shareReferral,
      onCopy: copyReferral
    }, null, 8, ["show"])
  ], 64))
}
}

})