import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, normalizeStyle as _normalizeStyle, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/leagueCoin.png'


const _hoisted_1 = ["src"]
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "boost-name" }
const _hoisted_4 = { class: "boost-effect" }
const _hoisted_5 = { class: "price-container" }
const _hoisted_6 = {
  key: 0,
  class: "coin",
  src: _imports_0,
  alt: "Coin"
}
const _hoisted_7 = { class: "boost-price" }
const _hoisted_8 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-slide-up" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "boost-modal-overlay",
            onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
          }, [
            _createElementVNode("div", {
              class: "boost-modal-content",
              onClick: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["stop"])),
              onMousedown: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.startDrag && _ctx.startDrag(...args))),
              onMousemove: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onDrag && _ctx.onDrag(...args))),
              onMouseup: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.endDrag && _ctx.endDrag(...args))),
              style: _normalizeStyle({ transform: `translateY(${_ctx.translateY}px)` })
            }, [
              _createElementVNode("img", {
                class: "close",
                src: require(`@/assets/close-modal.png`),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, null, 8, _hoisted_1),
              _createElementVNode("img", {
                class: "boost-image",
                src: _ctx.getBoostImage,
                alt: "Boost"
              }, null, 8, _hoisted_2),
              _createElementVNode("h2", _hoisted_3, _toDisplayString(_ctx.localizedBoostNameWithStat), 1),
              _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.localizedBoostEffect), 1),
              _createElementVNode("div", _hoisted_5, [
                (!_ctx.isMaxLevel)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_6))
                  : _createCommentVNode("", true),
                _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.localizedPriceText), 1)
              ]),
              _createElementVNode("button", {
                class: _normalizeClass({ disabled: _ctx.isMaxLevel || !_ctx.hasEnoughCoins }),
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.emitBuyBoost && _ctx.emitBuyBoost(...args))),
                disabled: _ctx.isMaxLevel || !_ctx.hasEnoughCoins
              }, _toDisplayString(_ctx.localizedButtonText), 11, _hoisted_8)
            ], 36)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}