import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "boost-page" }
const _hoisted_3 = { class: "title" }
const _hoisted_4 = { class: "user-coins" }
const _hoisted_5 = { class: "coins-title" }
const _hoisted_6 = ["src"]
const _hoisted_7 = { class: "coins" }
const _hoisted_8 = { class: "boost-container" }
const _hoisted_9 = { class: "boosts" }
const _hoisted_10 = { class: "tasks-component" }
const _hoisted_11 = { class: "tasks" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TonConnectButton = _resolveComponent("TonConnectButton")!
  const _component_BoostComponent = _resolveComponent("BoostComponent")!
  const _component_TaskComponent = _resolveComponent("TaskComponent")!
  const _component_BoostModal = _resolveComponent("BoostModal")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_TonConnectButton)
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('boost')), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t('your_balance')), 1),
        _createElementVNode("img", {
          class: "coin",
          src: require('@/assets/leagueCoin.png'),
          alt: "Coin"
        }, null, 8, _hoisted_6),
        _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.boostsStore.coins), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.boostsStore.boosts, (boost) => {
          return (_openBlock(), _createBlock(_component_BoostComponent, {
            key: boost.id,
            boost: boost,
            nextLevelPrice: _ctx.boostsStore.calculateNextLevelPrice(boost.first_level_price, boost.currentLevel),
            onOpenModal: ($event: any) => (_ctx.boostsStore.openModal(boost))
          }, null, 8, ["boost", "nextLevelPrice", "onOpenModal"]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_10, [
      _cache[1] || (_cache[1] = _createElementVNode("p", { class: "tasks-component-text" }, "SPECIAL MISSIONS", -1)),
      _createElementVNode("div", _hoisted_11, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tasksStore.tasks, (task) => {
          return (_openBlock(), _createBlock(_component_TaskComponent, {
            key: task.id,
            task: task
          }, null, 8, ["task"]))
        }), 128))
      ])
    ]),
    (_ctx.boostsStore.selectedBoost)
      ? (_openBlock(), _createBlock(_component_BoostModal, {
          key: 0,
          boost: _ctx.boostsStore.selectedBoost,
          "is-visible": _ctx.boostsStore.isModalVisible,
          "next-level-price": _ctx.boostsStore.calculateNextLevelPrice(_ctx.boostsStore.selectedBoost.first_level_price, _ctx.boostsStore.selectedBoost.currentLevel),
          onCloseModal: _ctx.boostsStore.closeModal,
          onBuyBoost: _cache[0] || (_cache[0] = ($event: any) => (_ctx.boostsStore.handleBuyBoost(_ctx.boostsStore.selectedBoost)))
        }, null, 8, ["boost", "is-visible", "next-level-price", "onCloseModal"]))
      : _createCommentVNode("", true)
  ]))
}