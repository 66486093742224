<template>
    <div v-if="waitingForOnboarding == true" class="waiting-onboarding">
      <div class="loading-ring"></div>
    </div>
    <OnBoardingComponent v-else-if="showCarousel == true" @close="setShowCarousel"></OnBoardingComponent>
    <div v-else class="container">
      <canvas style="display: none;" width="384" height="590"></canvas>
      <div class="user-info">
        <div class="user-header">
          
          <div class="left-side">
            
            <div class="user-head">
              <img
                v-if="userPhoto"
                class="user-avatar"
                :src="userPhoto"
                alt="User Photo"
              />
              <div v-else class="user-avatar-placeholder">
                {{ username.charAt(0).toUpperCase() }}
              </div>
              <h1 class="user-name">{{ username }}</h1>
            </div>
          
          </div>

          <div class="right-side" @click="redirectToLeaguePage">
            <div class="user-league">
              <img class="champion-cup" src="@/assets/champion-cup.png" alt="Champion cup"/>
              {{ getLocalizedLeague }}
              <div class="user-place" >{{ formatPlace(place) }}</div>  
              <img class="stroke-img" src="@/assets/stroke.png" alt="stroke"/>            
            </div>
          </div>

        </div>
      </div>
      
      <div class="points-container">
              <img class="coin" src="@/assets/coin.png" alt="Coin" />
              <div class="points">{{ formattedPoints }}</div>
      </div>
    
    <div v-if="placesFound" class="outer" >
      <div v-if="loading" class="skeleton" style="width: 500px; height: 312px;"></div>  
      <ScrollPlace v-else :places="placesRef" :timeLeft="timeLeft" :loading="loading"/>
    </div>

    <div v-else class="places-not-found">
      <p>{{ $t('no_places') }}</p>
    </div>

    
    <div v-if="timeLeft > 0" class="progress-container">
      <div class="progress-bar-wrapper">
        <div class="progress-bar">
          <div class="progress" :style="{ width: progressBarWidth }">
            <img src="@/assets/progress.png" class="progress-image" />
          </div>
        </div>
        <div class="progress-time">
          <img class="locked" src="@/assets/locked.png" alt="Locked"/>
          {{ formattedTime }}</div>
      </div>
    </div>

    <div class="boost-button-container">
      <div class="description-checkin">
        <img class="search-icon" :src="require('@/assets/search.png')" alt="Search" />
        <p class="search-text">{{ $t('search') }}</p>
        <p class="search-description">{{ $t('showing_places') }}</p>
      </div>
      <router-link
        to="/boost"
        class="boost-button"
      >

      <img class="boost-icon" src="@/assets/boost.png" alt="Boost" />
        {{ $t('boost_checkins') }}
      </router-link>

    </div>

  </div>
</template>

<script setup lang="ts">
  import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
  import axios from 'axios';
  import { useRouter, useRoute } from 'vue-router';
  import ScrollPlace from '@/components/ScrollPlace.vue';
  import OnBoardingComponent from '@/components/OnBoardingComponent.vue';
  import { getLocalizedText } from '@/localizatoin';

  interface Place {
    id: string;
    name: string;
    category: string;
    latitude: number;
    longitude: number;
    phoneNumber: string;
    url: string;
    address: string;
    checkins: number;
    distance: any;
    // Добавьте другие поля, которые могут быть в объектах мест
  }

  const placesRef = ref<Place[]>([]);
  const selectedRate = ref<2 | 1 | null>(null);
  const selectedPlace = ref<Place | null>(null);

  const router = useRouter();
  const points = ref(0);
  const place = ref(0);
  const league = ref('Gold');
  const league_id = ref(0);
  const loading = ref(true);
  const username = ref('username');
  const userPhoto = ref('');
  const route = useRoute();
  const placesFound = ref(true);
  const userDataLoading = ref(true);
  const onboardingViewed = ref();
  const waitingForOnboarding = ref(sessionStorage.getItem('viewedOnboarding') ? sessionStorage.getItem('viewedOnboarding') : true);
  const showCarousel = ref(false);
  
  const getLocalizedLeague = computed(() => {
    return getLocalizedText(`league_${league.value}` )
  })

  onMounted(async () => {
  const userLanguage = window.Telegram.WebApp.initDataUnsafe.user?.language_code;

  console.log("User Language:", userLanguage);

  // Сохранение языка пользователя в localStorage
  localStorage.setItem('user_language', userLanguage);
  // Достаем данные пользователя, полученные при запуске мини приложения
  const telegramId = localStorage.getItem('telegram_id');
  const usernameStart = localStorage.getItem('username');
  const refferalCode = localStorage.getItem('invited_by');

  if (!telegramId || !usernameStart) {
    console.error('No telegram_id or username found');
    return;
  }

  try {
    const response = await axios.get('/api/check_user', {
      params: {
        telegram_id: telegramId,
        username: usernameStart,
        referral_code: refferalCode,
      },
    });

    if (response.data.status === 'success') {
      await fetchUserData(telegramId);
      await fetchLeaderboardData(telegramId);

      // Проверка загрузки MapKit
      if (!window.mapkit || !window.mapkit.loadedLibraries || window.mapkit.loadedLibraries.length === 0) {
        await new Promise<void>((resolve) => { window.initMapKit = resolve; });
        delete window.initMapKit;
      }

      const mapkitResponse = await fetch('/get-mapkit-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const mapkitData = await mapkitResponse.json();
      const token = mapkitData.token;

      window.mapkit.init({
        authorizationCallback: (done: (arg0: any) => void) => {
          done(token);
        },
        language: 'ru'
      });

      window.Telegram.WebApp.LocationManager.init(() => {
      window.Telegram.WebApp.LocationManager.getLocation((locationData: { latitude: any; longitude: any; }) => {
        if (locationData) {
          const userLatitude = locationData.latitude;
          const userLongitude = locationData.longitude;
          const userCoordinate = new window.mapkit.Coordinate(userLatitude, userLongitude);
          console.log(`Latitude: ${userLatitude}, Longitude: ${userLongitude}`);
          window.Telegram.WebApp.LocationManager.isInited();

          const filter = window.mapkit.PointOfInterestFilter.including([
            window.mapkit.PointOfInterestCategory.Airport,
            window.mapkit.PointOfInterestCategory.AmusementPark,
            window.mapkit.PointOfInterestCategory.Aquarium,
            window.mapkit.PointOfInterestCategory.ATM,
            window.mapkit.PointOfInterestCategory.Bakery,
            window.mapkit.PointOfInterestCategory.Bank,
            window.mapkit.PointOfInterestCategory.Beach,
            window.mapkit.PointOfInterestCategory.Brewery,
            window.mapkit.PointOfInterestCategory.Cafe,
            window.mapkit.PointOfInterestCategory.Campground,
            window.mapkit.PointOfInterestCategory.CarRental,
            window.mapkit.PointOfInterestCategory.EVCharger,
            window.mapkit.PointOfInterestCategory.FireStation,
            window.mapkit.PointOfInterestCategory.FitnessCenter,
            window.mapkit.PointOfInterestCategory.FoodMarket,
            window.mapkit.PointOfInterestCategory.GasStation,
            window.mapkit.PointOfInterestCategory.Hospital,
            window.mapkit.PointOfInterestCategory.Hotel,
            window.mapkit.PointOfInterestCategory.Laundry,
            window.mapkit.PointOfInterestCategory.Library,
            window.mapkit.PointOfInterestCategory.Marina,
            window.mapkit.PointOfInterestCategory.MovieTheater,
            window.mapkit.PointOfInterestCategory.Museum,
            window.mapkit.PointOfInterestCategory.NationalPark,
            window.mapkit.PointOfInterestCategory.Nightlife,
            window.mapkit.PointOfInterestCategory.Park,
            window.mapkit.PointOfInterestCategory.Parking,
            window.mapkit.PointOfInterestCategory.Pharmacy,
            window.mapkit.PointOfInterestCategory.Police,
            window.mapkit.PointOfInterestCategory.PostOffice,
            window.mapkit.PointOfInterestCategory.PublicTransport,
            window.mapkit.PointOfInterestCategory.Restaurant,
            window.mapkit.PointOfInterestCategory.Restroom,
            window.mapkit.PointOfInterestCategory.School,
            window.mapkit.PointOfInterestCategory.Stadium,
            window.mapkit.PointOfInterestCategory.Store,
            window.mapkit.PointOfInterestCategory.Theater,
            window.mapkit.PointOfInterestCategory.University,
            window.mapkit.PointOfInterestCategory.Winery,
            window.mapkit.PointOfInterestCategory.Zoo
          ]);

          const search = new window.mapkit.PointsOfInterestSearch({
            center: userCoordinate,
            radius: 50,
            pointOfInterestFilter: filter
          });

          search.search(async (error: any, data: { places: any[]; }) => {
            if (error) {
              console.error('Error during POI search:', error);
              return;
            }

            console.log(data.places);
            console.log(data.places.length);

            const placesWithAddressPromises = data.places
              .filter((place) => place.pointOfInterestCategory)
              .map((place) => {
                const distance = calculateDistance(
                  userLatitude,
                  userLongitude,
                  place.coordinate.latitude,
                  place.coordinate.longitude
                );

                return {
                  id: place.id,
                  name: place.name,
                  category: place.pointOfInterestCategory,
                  latitude: place.coordinate.latitude,
                  longitude: place.coordinate.longitude,
                  phoneNumber: place.telephone,
                  url: place.urls ? place.urls[0] : 'No URL',
                  address: place.fullThoroughfare,
                  checkins: 0, // или другое значение по умолчанию
                  distance: distance // рассчитанное расстояние
                };
              });

            const placesWithAddress = await Promise.all(placesWithAddressPromises);

            // Сортировка по расстоянию
            placesWithAddress.sort((a, b) => a.distance - b.distance);

            placesRef.value = placesWithAddress;

            console.log('Filtered places:', placesWithAddress);

            if (telegramId && placesRef.value.length > 0) {
              try {
                const response = await axios.post(`/api/places-with-checkins`, {
                  telegram_id: telegramId,
                  places: placesWithAddress
                });

                placesRef.value = response.data.places;
                console.log('Places with checkins:', placesRef.value);

                placesFound.value = placesRef.value.length > 0;
              } catch (error) {
                console.error('Error fetching places with checkins:', error);
              } finally {
                loading.value = false;
              }
            } else {
              loading.value = false;
              placesFound.value = false;
              console.log('No places found after fetching checkins');
            }
          });
        } else {
          console.error("Доступ к геопозиции не предоставлен или геослужбы недоступны.");
          // При необходимости можно вызвать:
          // LocationManager.openSettings();
        }
      });
    });

    } else {
      console.error('User check failed:', response.data.message);
    }
  } catch (error) {
    console.error('Error checking user:', error);
  }
  });

  // Вычисляемое свойство для форматирования числа
  const formattedPoints = computed(() => {
    return points.value.toLocaleString('en-US');
  });

  window.Telegram.WebApp.ready();

  // Показываем кнопку "Назад"
  window.Telegram.WebApp.BackButton.show();

  // Обрабатываем клик по кнопке "Назад"
  window.Telegram.WebApp.BackButton.onClick(() => {
    // Действие при нажатии на кнопку "Назад"
    // Например, возврат на предыдущий экран
    window.history.back(); // Если хотите просто вернуться на предыдущую страницу
    // Или любое другое действие, которое вам нужно
  });

  function setShowCarousel(){
    showCarousel.value = false;
  }

  // Функция для расчета маршрута и получения расстояния
  function calculateDistance(userLatitude: number, userLongitude: number, destLatitude: number, destLongitude: number): number {
    const R = 6371e3; // Радиус Земли в метрах
    const φ1 = userLatitude * Math.PI / 180; // φ, λ в радианах
    const φ2 = destLatitude * Math.PI / 180;
    const Δφ = (destLatitude - userLatitude) * Math.PI / 180;
    const Δλ = (destLongitude - userLongitude) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // в метрах
    return Math.round(distance);
  }

  const getCheckinTime = async (telegramId: string) => {
    axios.get('/checkin_time', {
        params: {
            telegram_id: telegramId 
        }
      })
      .then(response => {
        const userData = response.data;
        const totalSeconds = userData.total_time;
        const currentSeconds = userData.seconds_since_checkin;

        setTime(totalSeconds, currentSeconds);
        startCountdown(); // Запускаем обратный отсчет

      })
      .catch(error => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
  }

  const fetchUserData = async (telegramId: string) => {
    try {
      const response = await axios.get(`/api/get_username?telegram_id=${telegramId}`);
      if (response.data && response.data.username) {
        username.value = response.data.username;
        userPhoto.value = response.data.photo ? `data:image/png;base64,${response.data.photo}` : '';
        const userData = {
          username: response.data.username,
          photo: response.data.photo,
          viewed: response.data.viewed
        };

        onboardingViewed.value = userData.viewed;
        console.log('onboardingViewed:', onboardingViewed.value);

        waitingForOnboarding.value = false;
        console.log('waitingForOnboarding:', waitingForOnboarding.value);

        if (onboardingViewed.value === false) {
            showCarousel.value = true;
            console.log('Show Carousel:', showCarousel.value);
        }
        
        sessionStorage.setItem('viewedOnboarding', JSON.stringify(false));
        
        sessionStorage.setItem(`user_${telegramId}`, JSON.stringify(userData));
      } else {
        console.error('Username not found in response:', response.data);
      }
    } catch (error) {
      console.error('Error fetching username:', error);
    }
  };

  const formatPlace = (place: number) => {
    const j = place % 10;
    const k = place % 100;
    if (j == 1 && k != 11) {
      return place + 'st';
    }
    if (j == 2 && k != 12) {
      return place + 'nd';
    }
    if (j == 3 && k != 13) {
      return place + 'rd';
    }
    return place + 'th';
  };

  const fetchLeaderboardData = async (telegramId: string) => {
    try {
      const response = await axios.get(`/api/leaderboard/${telegramId}`);
      if (response.data) {
        points.value = response.data.coins;
        place.value = response.data.rank;
        league.value = response.data.league;
        league_id.value = response.data.league_id;
      } else {
        console.error('No data found in response:', response.data);
      }
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  const totalTime = ref(0); // Общее время обратного отсчета в секундах (24 часа)
  const timeLeft = ref(totalTime.value);
  const buttonColor = ref('#858585');
  let interval: any;

  const setTime = (totalSeconds: number, leftSeconds: number) => {
    totalTime.value = totalSeconds;
    timeLeft.value = leftSeconds > 0 ? leftSeconds : 0; // Убедиться, что leftSeconds не отрицателен
  };

  const startCountdown = () => {
    interval = setInterval(() => {
      if (timeLeft.value > 0) {
        timeLeft.value -= 1;
      } else {
        clearInterval(interval); // Останавливаем интервал, если время закончилось
        buttonColor.value = '#e7640b'; // Оранжевый цвет кнопки, если время закончилось
      }
    }, 1000);
  };

 const redirectToLeaguePage = () => {
   const leagueId = league_id.value;
   const userName = username.value; // Получаем значение username
   router.push({ name: 'LeaguePage', query: { league_id: leagueId, username: userName } });
 };


  onMounted(() => {
    axios.get('/checkin_time', {
        params: {
            telegram_id: localStorage.getItem('telegram_id') 
        }
      })
      .then(response => {
        const userData = response.data;
        const totalSeconds = userData.total_time;
        const currentSeconds = userData.seconds_since_checkin;

        setTime(totalSeconds, currentSeconds);
        startCountdown(); // Запускаем обратный отсчет

      })
      .catch(error => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
  });

  const checkIn = () => {
    window.location.reload();
  };

  const dashArray = computed(() => {
    const percentage = (timeLeft.value / totalTime.value) * 100;
    const circumference = 2 * Math.PI * 50; // Окружность круга с радиусом 50
    const filled = (percentage / 100) * circumference;
    return `${filled}, ${circumference}`;
  });

  const progressBarWidth = computed(() => {
    return `${(timeLeft.value / totalTime.value) * 100}%`;
  });

  const formattedTime = computed(() => {
    const hours = Math.floor(timeLeft.value / 3600);
    const minutes = Math.floor((timeLeft.value % 3600) / 60);
    const seconds = Math.floor(timeLeft.value % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  });
</script>
    
<style scoped>

.loading-ring {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  width: 40px;
  height: 40px;
  min-width: 40px; 
  min-height: 40px;
  animation: spin 1s linear infinite;
  display: inline-block;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
  
.waiting-onboarding {
  width: 100%;
  height: 100vh;
  z-index: 1005;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--Native-secondary_bg_color, #0F0F0F);
  position: absolute;
  top: 0;
  left: 0;
}

canvas {
  background: #000;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    z-index: -1;
}

.places-not-found{
  width: 100%;
  height: 312px;
  font-size: 20px;
  color: aliceblue;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.search-description {
  color: var(--Native-hint_color, #AAAAAA);
  font-size: 15px;
  font-weight: 400;
  margin: 0px;
}

.search-text {
  color: var(--Native-link_color, #2990FF);
  font-size: 15px;
  font-weight: 590;
  margin: 0px 8px 0px 4px;
}


.search-icon {
  width: 18px;
  height: 18px;
  animation: spin 2s linear infinite;
}

.description-checkin {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: 32px;
}

.locked {
  width: 20px;
  height: 20px;
}

.user-avatar-placeholder {
  width: 24px;
  height: 24px;
  background-color: #ccc; /* Цвет фона подберите по своему вкусу */
  border-radius: 50%; /* Скругление, чтобы был круг */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px; /* Размер шрифта подберите по своему вкусу */
  color: white; /* Цвет текста */
  margin-right: 8px;
}

.boost-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 100px;
}

.progress-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: 36px;
  width: 100%;
  height: auto;
  padding: 36px 16px 0px 16px;
}

.progress-bar-wrapper {
  position: relative;
  width: 100%;
}

.progress-time {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-size: 17px;
  font-weight: 590;
  color: aliceblue;
  gap: 4px;
}

.progress-bar {
  width: 100%;
  height: 30px;
  background-color: var(--Custom-plain_background, #4b4b4b);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
}

.progress {
  height: 100%;
  display: flex;
  align-items: center;
}

.progress-image {
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  width: 100%;
}

.progress-time {
  font-size: 17px;
    font-weight: 590;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--Custom-plain_background, #ffffff14);
    width: 100%;
    border-radius: 50px;
    padding: 4px 0px;
    color: aliceblue;
}

.outer {
  overflow: scroll;
  width: 100%;

}

.outer {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.outer::-webkit-scrollbar {
  display: none;  /* Chrome, Safari, Opera */
}

.place-card {
  display: inline-block;
  margin-right: 16px;
}

.user-head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.user-head h1 {
  color: aliceblue;
}

.user-avatar {
  border-radius: 50%;
  margin-right: 8px;
  width: 24px;
  height: 24px; /* Добавляем высоту для квадратного контейнера */
  object-fit: cover; /* Масштабируем изображение, чтобы оно заполнило контейнер, сохраняя пропорции */
  object-position: center; /* Центрируем изображение внутри контейнера */
}

.user-name {
  font-size: 17px;
  font-weight: 400;
  margin: 0;
}

.progress-circle-skeleton {
  display: inline-block;
  width: 250px;
  height: 250px;
  background: linear-gradient(90deg, rgba(128, 128, 128, 0.1) 25%, rgba(224, 224, 224, 0.1) 50%, rgba(128, 128, 128, 0.1) 75%);
  background-size: 200px 100%;
  animation: skeleton-loading 2.5s infinite;
  border-radius: 50%;
}

@keyframes skeleton-loading {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.stroke-img {
  height: 12px;
  margin-left: 4px;
}

.boost-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 358px;
  height: 45px;
  margin-top: 16px;
  background: var(--Custom-plain_background, #FFFFFF14);
  border-radius: 10px;
  color: aliceblue;
  gap: 10px;
  text-decoration: none;
}

.boost-icon {
  width: 24px;
  height: 24px;
}

.skeleton {
  display: inline-block;
  height: 100%;
  width: 100%;
  background: linear-gradient(90deg, rgba(128, 128, 128, 0.1) 25%, rgba(224, 224, 224, 0.1) 50%, rgba(128, 128, 128, 0.1) 75%);
  background-size: 200px 100%;
  animation: skeleton-loading 2.5s infinite;
  border-radius: 7px;
}

.inactive {
  pointer-events: none;
  opacity: 0.5;
}

.champion-cup {
  width: 22px;
  height: 22px;
}

.user-league {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  color: aliceblue;
  font-size: 17px;
  font-weight: 510;
  align-items: center;
  gap: 8px;
}

.user-place {
  display: flex;
  justify-content: flex-end;
  font-size: 17px;
  font-weight: 400;
  color: #AAAAAA;
  text-decoration: none;
}

.referrals-button{
  background: #f79416;
  color: aliceblue;
  text-decoration: none;
  height: 35px;
  display: flex;
  align-items: center;
  width: 145px;
  justify-content: center;
  border-radius: 10px;
}

.refferals-button {
  text-decoration: none;
  color: aliceblue;
  background: #f38628;
  height: 33px;
  display: flex;
  width: 110px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgb(255 255 255 / 0.08);
  padding: 4px 12px;
  border-radius: 50px;
}

.points-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.points {
  color: aliceblue;
  font-size: 52px;
  font-weight: 400;
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.user-header, .user-bottom {
  display: flex;
  flex-direction: row;
  width: 95%;
  justify-content: space-between;
  margin-top: 16px;
  align-items: center;
}

.user-bottom{
  margin-top: 10px;
  margin-bottom: 110px;
}

.left-side{
  display: flex;
  flex-direction: column;
}

.points-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 36px 0px;
  gap: 16px;
}

.coin{
  width: 54px;
  border-radius: 50%; /* чтобы изображение стало круглым */
  box-shadow: 
    1.29px 3.86px 7.71px rgba(255, 205, 30, 0.47), /* желтая тень */
    -2.57px -1.29px 7.71px rgba(255, 255, 255, 0.31); /* белая тень */
}

.check-in {
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 24px;
  color: aliceblue;
  background-color: #e7640b;
  width: 250px;
  height: 50px;
  text-align: center;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: calc(100dvh + 1px);  
  overflow: hidden;
  padding-bottom: calc(80px + var(--lvh, 100vh) - var(--tg-viewport-height, 100vh));
}

.progress-circle {
  width: 300px; /* Увеличиваем размер круга */
  height: 300px; /* Увеличиваем размер круга */
}

.circle-bg {
  fill: none;
  stroke: #eee;
  stroke-width: 5;
}

.circle {
  fill: none;
  stroke: url(#gradient);
  stroke-width: 5;
  stroke-linecap: round;
  transition: stroke-dasharray 1s linear;
}

.percentage {
  fill: #dddddd;
  font-family: Arial,sans-serif;
  font-size: 8px;
  text-anchor: middle;
  font-weight: 500;
}
</style>
