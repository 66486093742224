<template>
  <div class="boost" @click="openModal">
    <div class="boost-container">
      <img class="boost-image" :src="getBoostImage" alt="Boost" />
      <div class="boost-info-container">
        <div class="boost-info">
          <h3 class="boost-name">{{ localizedBoostNameWithStat }}</h3>
          <p class="boost-effect">{{ localizedBoostEffect }}</p>
          <div class="price-container">
            <img class="coin" v-if="!isMaxLevel" src="@/assets/leagueCoin.png" alt="Coin" />
            <p class="boost-price">{{ priceText }}</p>
          </div>
        </div>
        <img class="stroke-img" src="@/assets/strokeGrey.png" alt="stroke" />
    </div>
    </div>
  </div>
</template>

<script lang="ts">
import { getLocalizedText } from '@/localizatoin';
import { defineComponent, computed, PropType, inject } from 'vue';

export default defineComponent({
  name: 'BoostComponent',
  props: {
    boost: {
      type: Object as PropType<{
        id: number;
        name: string;
        first_level_price: number;
        description: string;
        max_level: number;
        currentLevel: number;
        effect: string;
      }>,
      required: true
    },
    nextLevelPrice: {
      type: Number,
      required: true
    }
  },
  emits: ['open-modal'],
  setup(props, { emit }) {

    const $t = inject('$t') as (key: string, params?: any) => string;

    const getBoostImage = computed(() => {
      return props.boost.name === 'Check-in timer' ? require('@/assets/clock.png') : require('@/assets/traveller.png');
    });

    const isMaxLevel = computed(() => {
      return props.boost.currentLevel >= props.boost.max_level;
    });

    const priceText = computed(() => {
      return isMaxLevel.value ? 'MAX.' : props.nextLevelPrice + getLocalizedText('for_l') + (props.boost.currentLevel + 1);
    });

    const openModal = () => {
      emit('open-modal', props.boost);
    };

    const localizedBoostEffect = computed(() => {
      if (props.boost.name === 'Traveller') {
        return $t ? $t('boost_effect_checkin_score') : getLocalizedText('boost_effect_checkin_score');
      } else if (props.boost.name === 'Check-in timer') {
        return $t ? $t('boost_effect_decrease_time') : getLocalizedText('boost_effect_decrease_time');
      } else {
        // Если тип буста не распознан, используем оригинальное описание
        return props.boost.effect;
      }
    });

    const localizedBoostNameWithStat = computed(() => {
      let boostName = '';
      let boostStat = '';

      // Локализуем имя буста
      if (props.boost.name === 'Check-in timer') {
        boostName = $t ? $t('boost_checkin_timer') : getLocalizedText('boost_checkin_timer');
      } else if (props.boost.name === 'Traveller') {
        boostName = $t ? $t('boost_traveller') : getLocalizedText('boost_traveller');
      } else {
        boostName = props.boost.name;
      }

      // Формируем статистику буста
      if (props.boost.name === 'Check-in timer') {
        const times = [20, 15, 10, 5];
        // Ограничиваем индекс массива:
        const index = Math.min(props.boost.currentLevel, times.length - 1);
        boostStat = $t 
          ? $t('boost_stat_min', { time: times[index] })
          : getLocalizedText('boost_stat_min').replace('{time}', times[index].toString());
      } else if (props.boost.name === 'Traveller') {
        const multipliers = [1.25, 1.5, 2, 2.5];
        const index = Math.min(props.boost.currentLevel, multipliers.length - 1);
        boostStat = $t
          ? $t('boost_stat_multiplier', { multiplier: multipliers[index] })
          : getLocalizedText('boost_stat_multiplier').replace('{multiplier}', multipliers[index].toString());
      } else {
        boostStat = props.boost.description;
      }

      return `${boostName} / ${boostStat}`;
    });


    const boostStat = computed(() => {
        if (props.boost.name === 'Check-in timer') {
          switch (props.boost.currentLevel) {
            case 0:
              return ' / 20 min';
            case 1:
              return ' / 15 min';
            case 2:
              return ' / 10 min';
            case 3:
              return ' / 5 min';
            default:
              return '';
          }
        } else if (props.boost.name === 'Traveller') {
          switch (props.boost.currentLevel) {
            case 0:
              return ' / 1.25x';
            case 1:
              return ' / 1.5x';
            case 2:
              return ' / 2x';
            case 3:
              return ' / 2.5x';
            default:
              return '';
          }
        }
        return props.boost.description;
      });

    return {
      getBoostImage,
      isMaxLevel,
      priceText,
      openModal,
      boostStat,
      localizedBoostNameWithStat,
      localizedBoostEffect
    };
  }
});
</script>

<style scoped>

.boost-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.stroke-img {
  margin-right: 21px;
  width: 5px;
  height: 10px;
}

.boost-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
}

.boost-image {
  width: 74px;
  height: 74px;
}

.boost-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.boost-price {
  font-size: 17px;
  margin: 0px;
  font-weight: 400;
  color: var(--Native-text_color, #FFFFFF);
}

.boost-name {
  margin: 0px;
  font-size: 17px;
  color: var(--Native-text_color, #FFFFFF);
  font-weight: 510;
  margin-top: 12px;
}

.boost-effect {
  margin: 0px;
  font-size: 14px;
  color: var(--Native-hint_color, #AAAAAA);
  font-weight: 400;
  max-width: 202px;
}

.boost {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background: var(--Native-bg_color, #212121);
  color: #f0f8ff;
  width: 85%;
  border-radius: 16px;
  height: auto;
  padding: 0px 16px;
  margin: 0px 16px 20px 16px;
}

.price-container {
  color: var(--Native-text_color, #FFFFFF);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  justify-content: center;
  margin-bottom: 12px;
  margin-top: 8px;
}

.coin {
  width: 20px;
  height: 20px;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
</style>
