import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "user-header" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "user-name" }
const _hoisted_4 = { class: "buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "user-avatar",
        src: _ctx.userPhoto,
        alt: "User Photo"
      }, null, 8, _hoisted_2),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.username), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_router_link, {
        to: "/referrals",
        class: "referrals-button"
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createTextVNode(" My referrals ")
        ])),
        _: 1
      })
    ])
  ], 64))
}