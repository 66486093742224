import { defineComponent as _defineComponent } from 'vue'
import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"
import _imports_0 from '@/assets/gps.png'


const _hoisted_1 = { class: "place-card" }
const _hoisted_2 = { class: "foreground-content" }
const _hoisted_3 = { class: "image-container" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "place-info-container" }
const _hoisted_6 = { class: "place-info" }
const _hoisted_7 = { class: "place-title" }
const _hoisted_8 = { class: "distance" }

import { watchEffect } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'PlaceCard',
  props: {
  imageSrc: String,
  name: String,
  address: String,
  checkins: Number,
  timeLeft: {
    type: Number,
    required: true,
    default: 0,
  },
  loading: Boolean,
  distance: Number,
},
  setup(__props) {

const props = __props;

// Добавление логирования для проверки обновлений timeLeft
watchEffect(() => {
  console.log("timeLeft изменился: ", props.timeLeft);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "background-image",
      style: _normalizeStyle({ backgroundImage: `url(${__props.imageSrc})` })
    }, null, 4),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: __props.imageSrc,
          alt: "Place Image",
          class: "place-image"
        }, null, 8, _hoisted_4)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, _toDisplayString(__props.name), 1),
          _createElementVNode("div", _hoisted_8, [
            _cache[1] || (_cache[1] = _createElementVNode("img", {
              class: "gps-icon",
              src: _imports_0,
              alt: "GPS Icon"
            }, null, -1)),
            _createTextVNode(" " + _toDisplayString(__props.distance) + "m", 1)
          ])
        ]),
        (__props.timeLeft < 0.1)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "checkin-button",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('checkIn')))
            }, _toDisplayString(_ctx.$t('check_in')), 1))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})