import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = { class: "discovery-title" }
const _hoisted_3 = { class: "emoji-scroll" }
const _hoisted_4 = { class: "emoji-container" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["src"]
const _hoisted_7 = ["src"]
const _hoisted_8 = ["src"]
const _hoisted_9 = ["src"]
const _hoisted_10 = ["src"]
const _hoisted_11 = ["src"]
const _hoisted_12 = ["src"]
const _hoisted_13 = ["src"]
const _hoisted_14 = ["src"]
const _hoisted_15 = ["src"]
const _hoisted_16 = { class: "text-input" }
const _hoisted_17 = ["placeholder"]
const _hoisted_18 = { class: "char-count" }
const _hoisted_19 = { class: "pin-info" }
const _hoisted_20 = { class: "price-buttons" }
const _hoisted_21 = {
  key: 1,
  class: "price-button",
  style: { background: '#7f7f7f' }
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-slide-up" }, {
    default: _withCtx(() => [
      (_ctx.isVisible)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: "discovery-modal-overlay",
            onClick: _cache[17] || (_cache[17] = 
//@ts-ignore
(...args) => (_ctx.handleOverlayClick && _ctx.handleOverlayClick(...args)))
          }, [
            _createElementVNode("div", {
              class: "discovery-modal-content",
              ref: "modal",
              onClick: _cache[16] || (_cache[16] = _withModifiers(() => {}, ["stop"]))
            }, [
              _cache[18] || (_cache[18] = _createElementVNode("div", { class: "swipe-close" }, null, -1)),
              _createElementVNode("img", {
                class: "close",
                src: require(`@/assets/close-modal.png`),
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
              }, null, 8, _hoisted_1),
              _createElementVNode("h2", _hoisted_2, _toDisplayString(_ctx.$t('addpin')), 1),
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, [
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'like' }]),
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectEmoji('like')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/like.png`),
                      alt: "emoji1"
                    }, null, 8, _hoisted_5)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'heart-eye' }]),
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectEmoji('heart-eye')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/heart-eye.png`),
                      alt: "emoji2"
                    }, null, 8, _hoisted_6)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'death' }]),
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectEmoji('death')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/death.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_7)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'fire' }]),
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectEmoji('fire')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/fire.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_8)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'poop' }]),
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.selectEmoji('poop')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/poop.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_9)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'fack' }]),
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.selectEmoji('fack')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/fack.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_10)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'ghost' }]),
                    onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.selectEmoji('ghost')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/ghost.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_11)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'cold' }]),
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.selectEmoji('cold')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/cold.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_12)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'ufo' }]),
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.selectEmoji('ufo')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/ufo.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_13)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'no-hodit' }]),
                    onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.selectEmoji('no-hodit')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/no-hodit.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_14)
                  ], 2),
                  _createElementVNode("div", {
                    class: _normalizeClass(["emoji", { selected: _ctx.selectedEmoji === 'grob' }]),
                    onClick: _cache[11] || (_cache[11] = ($event: any) => (_ctx.selectEmoji('grob')))
                  }, [
                    _createElementVNode("img", {
                      class: "emoji-img",
                      src: require(`@/assets/rate/svg/grob.png`),
                      alt: "emoji3"
                    }, null, 8, _hoisted_15)
                  ], 2)
                ])
              ]),
              _createElementVNode("div", _hoisted_16, [
                _withDirectives(_createElementVNode("textarea", {
                  ref: "textareaRef",
                  type: "text",
                  class: "message-input",
                  placeholder: _ctx.$t('leave_message'),
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_ctx.message) = $event)),
                  onInput: _cache[13] || (_cache[13] = 
//@ts-ignore
(...args) => (_ctx.updateCharCount && _ctx.updateCharCount(...args)))
                }, "        ", 40, _hoisted_17), [
                  [_vModelText, _ctx.message]
                ]),
                _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.charCount) + "/140", 1)
              ]),
              _createElementVNode("p", _hoisted_19, _toDisplayString(_ctx.$t('pinstay')), 1),
              _createElementVNode("div", _hoisted_20, [
                (_ctx.coins > 999)
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      class: "price-button",
                      onClick: _cache[14] || (_cache[14] = 
//@ts-ignore
(...args) => (_ctx.submitPin && _ctx.submitPin(...args)))
                    }, "1000"))
                  : (_openBlock(), _createElementBlock("button", _hoisted_21, "1000")),
                _createElementVNode("button", {
                  class: "price-button",
                  onClick: _cache[15] || (_cache[15] = 
//@ts-ignore
(...args) => (_ctx.buyPin && _ctx.buyPin(...args)))
                }, "$0.99")
              ])
            ], 512)
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}