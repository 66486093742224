// src/stores/boostsStore.ts
import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export interface Boost {
  id: number;
  name: string;
  first_level_price: number;
  description: string;
  max_level: number;
  currentLevel: number;
  effect: string;
  isMaxed?: boolean;
}

export interface UserBoost {
  id_user: number;
  id_boost: number;
  current_level: number;
}

export const useBoostsStore = defineStore('boosts', () => {
  const boosts = ref<Boost[]>([]);
  const selectedBoost = ref<Boost | null>(null);
  const isModalVisible = ref(false);
  const coins = ref<number>(0);

  const fetchBoosts = async () => {
    const telegramId = localStorage.getItem('telegram_id');
    if (!telegramId) {
      console.error('Telegram ID не найден в localStorage');
      return;
    }

    try {
      const response = await axios.get('/api/boosts', { params: { telegram_id: telegramId } });
      const allBoosts: Boost[] = response.data.allBoosts;
      const userBoosts: UserBoost[] = response.data.userBoosts;

      boosts.value = allBoosts.map((boost) => {
        const userBoost = userBoosts.find((ub) => ub.id_boost === boost.id);
        const currentLevel = userBoost ? userBoost.current_level : 0;
        return {
          ...boost,
          currentLevel,
          effect: boost.description,
          isMaxed: currentLevel >= boost.max_level,
        };
      });

      // В store или в самом компоненте, после fetch:
      console.log('RESULT BOOSTS:', boosts.value);
      console.log(response.data);
      // Обновляем монеты
      await fetchLeaderboardData(telegramId);
    } catch (error) {
      console.error('Ошибка получения бустов:', error);
    }
  };

  const fetchLeaderboardData = async (telegramId: string) => {
    try {
      const response = await axios.get('/api/user_coins', {
        params: { telegram_id: telegramId },
      });
      if (response.data) {
        coins.value = response.data.coins;
        localStorage.setItem('coins', response.data.coins.toString());
      } else {
        console.error('Нет данных в ответе:', response.data);
      }
    } catch (error) {
      console.error('Ошибка получения данных монет:', error);
    }
  };

  const calculateNextLevelPrice = (firstLevelPrice: number, currentLevel: number) => {
    return firstLevelPrice * Math.pow(2, currentLevel);
  };

  const openModal = (boost: Boost) => {
    selectedBoost.value = boost;
    isModalVisible.value = true;
  };

  const closeModal = () => {
    selectedBoost.value = null;
    isModalVisible.value = false;
  };

  const handleBuyBoost = async (boost: Boost) => {
    const telegramId = localStorage.getItem('telegram_id');
    if (!telegramId) {
      console.error('Telegram ID не найден в localStorage');
      return;
    }

    // Если буст уже максимизирован, можно просто выйти или показать уведомление
    if (boost.isMaxed) {
      console.info('Буст уже достиг максимального уровня');
      return;
    }

    const nextLevelPrice = calculateNextLevelPrice(boost.first_level_price, boost.currentLevel);

    if (coins.value >= nextLevelPrice) {
      try {
        let apiUrl = '';
        if (boost.name === 'Check-in timer') {
          apiUrl = '/api/buy_time_boost';
        } else if (boost.name === 'Traveller') {
          apiUrl = '/api/buy_bonus_boost';
        } else {
          console.error('Неизвестный тип буста:', boost.name);
          return;
        }

        await axios.post(apiUrl, {
          telegram_id: telegramId,
          boost_id: boost.id,
          new_level: boost.currentLevel + 1,
        });

        coins.value -= nextLevelPrice;
        localStorage.setItem('coins', coins.value.toString());

        boost.currentLevel += 1;
        boost.effect = boost.description;
        // Обновляем флаг максимума после покупки
        boost.isMaxed = boost.currentLevel >= boost.max_level;

        // Обновляем баланс монет
        const response = await axios.get(`/api/user_coins`, {
          params: { telegram_id: telegramId },
        });
        if (response.data && response.data.coins !== undefined) {
          coins.value = response.data.coins;
          localStorage.setItem('coins', response.data.coins.toString());
        } else {
          console.error('Нет данных в ответе:', response.data);
        }
        
        closeModal();
      } catch (error) {
        console.error('Ошибка при покупке буста:', error);
      }
    } else {
      console.error('Недостаточно монет для покупки буста');
    }
  };

  return {
    boosts,
    selectedBoost,
    isModalVisible,
    coins,
    fetchBoosts,
    fetchLeaderboardData,
    calculateNextLevelPrice,
    openModal,
    closeModal,
    handleBuyBoost,
  };
});
