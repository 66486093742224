import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, Transition as _Transition, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/close-modal.png'
import _imports_1 from '@/assets/progress.png'
import _imports_2 from '@/assets/locked.png'


const _hoisted_1 = {
  key: 0,
  class: "container-modal"
}
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "content-wrapper" }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = {
  key: 0,
  class: "outer"
}
const _hoisted_6 = {
  key: 0,
  class: "skeleton",
  style: {"width":"500px","height":"228px"}
}
const _hoisted_7 = {
  key: 1,
  class: "places-not-found"
}
const _hoisted_8 = {
  key: 2,
  class: "progress-container"
}
const _hoisted_9 = { class: "progress-bar-wrapper" }
const _hoisted_10 = { class: "progress-bar" }
const _hoisted_11 = { class: "progress-time" }
const _hoisted_12 = { class: "boost-button-container" }
const _hoisted_13 = { class: "description-checkin" }
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "search-text" }
const _hoisted_16 = { class: "search-description" }

import { ref, computed, onMounted, watch, onBeforeUnmount } from 'vue';
  import axios from 'axios';
  import { useRouter, useRoute } from 'vue-router';
  import ScrollPlace from '@/components/ScrollPlace.vue';
  import OnBoardingComponent from '@/components/OnBoardingComponent.vue';
  import { getLocalizedText } from '@/localizatoin';
  import { useGeolocationStore } from '@/store/geolocation'

  interface Place {
    id: string;
    name: string;
    category: string;
    latitude: number;
    longitude: number;
    phoneNumber: string;
    url: string;
    address: string;
    checkins: number;
    distance: any;
    // Добавьте другие поля, которые могут быть в объектах мест
  }

  
export default /*@__PURE__*/_defineComponent({
  __name: 'CheckinComponent',
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    }
  },
  emits: ['update:isVisible', 'update-places'],
  setup(__props, { emit: __emit }) {

  const props = __props
  const placesRef = ref<Place[]>([]);
  const selectedRate = ref<2 | 1 | null>(null);
  const selectedPlace = ref<Place | null>(null);

  const router = useRouter();
  const points = ref(0);
  const place = ref(0);
  const league = ref('Gold');
  const league_id = ref(0);
  const loading = ref(true);
  const username = ref('username');
  const userPhoto = ref('');
  const route = useRoute();
  const placesFound = ref(true);
  const userDataLoading = ref(true);
  const onboardingViewed = ref();
  const waitingForOnboarding = ref(sessionStorage.getItem('viewedOnboarding') ? sessionStorage.getItem('viewedOnboarding') : true);
  const showCarousel = ref(false);
  
  const getLocalizedLeague = computed(() => {
    return getLocalizedText(`league_${league.value}` )
  })

  onMounted(async () => {
  const userLanguage = window.Telegram.WebApp.initDataUnsafe.user?.language_code;

  console.log("User Language:", userLanguage);

  // Сохранение языка пользователя в localStorage
  localStorage.setItem('user_language', userLanguage);
  // Достаем данные пользователя, полученные при запуске мини приложения
  const telegramId = localStorage.getItem('telegram_id');
  const usernameStart = localStorage.getItem('username');
  const refferalCode = localStorage.getItem('invited_by');

  if (!telegramId || !usernameStart) {
    console.error('No telegram_id or username found');
    return;
  }

  try {
    const response = await axios.get('/api/check_user', {
      params: {
        telegram_id: telegramId,
        username: usernameStart,
        referral_code: refferalCode,
      },
    });

    if (response.data.status === 'success') {
      await fetchUserData(telegramId);
      await fetchLeaderboardData(telegramId);

      // Проверка загрузки MapKit
      if (!window.mapkit || !window.mapkit.loadedLibraries || window.mapkit.loadedLibraries.length === 0) {
        await new Promise<void>((resolve) => { window.initMapKit = resolve; });
        delete window.initMapKit;
      }

      const mapkitResponse = await fetch('/get-mapkit-token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        }
      });

      const mapkitData = await mapkitResponse.json();
      const token = mapkitData.token;

      window.mapkit.init({
        authorizationCallback: (done: (arg0: any) => void) => {
          done(token);
        },
        language: window.Telegram.WebApp.initDataUnsafe.user?.language_code
      });

      // Получаем данные из Pinia store
      const geolocationStore = useGeolocationStore();
      await geolocationStore.fetchLocation();
      const userLatitude = geolocationStore.location.latitude;
      const userLongitude = geolocationStore.location.longitude;
      const userCoordinate = new window.mapkit.Coordinate(userLatitude, userLongitude);
      console.log(`Latitude: ${userLatitude}, Longitude: ${userLongitude}`);

      const filter = window.mapkit.PointOfInterestFilter.including([
        window.mapkit.PointOfInterestCategory.Airport,
        window.mapkit.PointOfInterestCategory.AmusementPark,
        window.mapkit.PointOfInterestCategory.Aquarium,
        window.mapkit.PointOfInterestCategory.ATM,
        window.mapkit.PointOfInterestCategory.Bakery,
        window.mapkit.PointOfInterestCategory.Bank,
        window.mapkit.PointOfInterestCategory.Beach,
        window.mapkit.PointOfInterestCategory.Brewery,
        window.mapkit.PointOfInterestCategory.Cafe,
        window.mapkit.PointOfInterestCategory.Campground,
        window.mapkit.PointOfInterestCategory.CarRental,
        window.mapkit.PointOfInterestCategory.EVCharger,
        window.mapkit.PointOfInterestCategory.FireStation,
        window.mapkit.PointOfInterestCategory.FitnessCenter,
        window.mapkit.PointOfInterestCategory.FoodMarket,
        window.mapkit.PointOfInterestCategory.GasStation,
        window.mapkit.PointOfInterestCategory.Hospital,
        window.mapkit.PointOfInterestCategory.Hotel,
        window.mapkit.PointOfInterestCategory.Laundry,
        window.mapkit.PointOfInterestCategory.Library,
        window.mapkit.PointOfInterestCategory.Marina,
        window.mapkit.PointOfInterestCategory.MovieTheater,
        window.mapkit.PointOfInterestCategory.Museum,
        window.mapkit.PointOfInterestCategory.NationalPark,
        window.mapkit.PointOfInterestCategory.Nightlife,
        window.mapkit.PointOfInterestCategory.Park,
        window.mapkit.PointOfInterestCategory.Parking,
        window.mapkit.PointOfInterestCategory.Pharmacy,
        window.mapkit.PointOfInterestCategory.Police,
        window.mapkit.PointOfInterestCategory.PostOffice,
        window.mapkit.PointOfInterestCategory.PublicTransport,
        window.mapkit.PointOfInterestCategory.Restaurant,
        window.mapkit.PointOfInterestCategory.Restroom,
        window.mapkit.PointOfInterestCategory.School,
        window.mapkit.PointOfInterestCategory.Stadium,
        window.mapkit.PointOfInterestCategory.Store,
        window.mapkit.PointOfInterestCategory.Theater,
        window.mapkit.PointOfInterestCategory.University,
        window.mapkit.PointOfInterestCategory.Winery,
        window.mapkit.PointOfInterestCategory.Zoo
      ]);

      const search = new window.mapkit.PointsOfInterestSearch({
        center: userCoordinate,
        radius: 150,
        pointOfInterestFilter: filter
      });

      search.search(async (error: any, data: { places: any[]; }) => {
        if (error) {
          console.error('Error during POI search:', error);
          return;
        }

        console.log(data.places);
        console.log(data.places.length);

        const placesWithAddressPromises = data.places
          .filter((place) => place.pointOfInterestCategory)
          .map((place) => {
            const distance = calculateDistance(
              userLatitude,
              userLongitude,
              place.coordinate.latitude,
              place.coordinate.longitude
            );

            return {
              id: place.id,
              name: place.name,
              category: place.pointOfInterestCategory,
              latitude: place.coordinate.latitude,
              longitude: place.coordinate.longitude,
              phoneNumber: place.telephone,
              url: place.urls ? place.urls[0] : 'No URL',
              address: place.fullThoroughfare,
              checkins: 0, // или другое значение по умолчанию
              distance: distance // добавляем рассчитанное расстояние
            };
          });

        const placesWithAddress = await Promise.all(placesWithAddressPromises);

        // Сортировка по расстоянию
        placesWithAddress.sort((a, b) => a.distance - b.distance);

        placesRef.value = placesWithAddress;

        console.log('Filtered places:', placesWithAddress);

        if (telegramId && placesRef.value.length > 0) {
          try {
            const response = await axios.post(`/api/places-with-checkins`, {
              telegram_id: telegramId,
              places: placesWithAddress
            });

            placesRef.value = response.data.places;
            console.log('Places with checkins:', placesRef.value);

            placesFound.value = placesRef.value.length > 0;
            // Отправляем данные в родительский компонент
            emit('update-places', placesRef.value);
          } catch (error) {
            console.error('Error fetching places with checkins:', error);
          } finally {
            loading.value = false;
          }
        } else {
          loading.value = false;
          placesFound.value = false;
          console.log('No places found after fetching checkins');
        }
      });
    } else {
      console.error('User check failed:', response.data.message);
    }
  } catch (error) {
    console.error('Error checking user:', error);
  }
  });

  const emit = __emit;

  const closeModal = () => {
    emit('update:isVisible', false); // Отправляем событие с новым значением
  };

  // Вычисляемое свойство для форматирования числа
  const formattedPoints = computed(() => {
    return points.value.toLocaleString('en-US');
  });

  function setShowCarousel(){
    showCarousel.value = false;
  }

  // Функция для расчета маршрута и получения расстояния
  function calculateDistance(userLatitude: number, userLongitude: number, destLatitude: number, destLongitude: number): number {
    const R = 6371e3; // Радиус Земли в метрах
    const φ1 = userLatitude * Math.PI / 180; // φ, λ в радианах
    const φ2 = destLatitude * Math.PI / 180;
    const Δφ = (destLatitude - userLatitude) * Math.PI / 180;
    const Δλ = (destLongitude - userLongitude) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    const distance = R * c; // в метрах
    return Math.round(distance);
  }

  const getCheckinTime = async (telegramId: string) => {
    axios.get('/checkin_time', {
        params: {
            telegram_id: telegramId 
        }
      })
      .then(response => {
        const userData = response.data;
        const totalSeconds = userData.total_time;
        const currentSeconds = userData.seconds_since_checkin;

        setTime(totalSeconds, currentSeconds);
        startCountdown(); // Запускаем обратный отсчет

      })
      .catch(error => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
  }

  const fetchUserData = async (telegramId: string) => {
    try {
      const response = await axios.get(`/api/get_username?telegram_id=${telegramId}`);
      if (response.data && response.data.username) {
        username.value = response.data.username;
        userPhoto.value = response.data.photo ? `data:image/png;base64,${response.data.photo}` : '';
        const userData = {
          username: response.data.username,
          photo: response.data.photo,
          viewed: response.data.viewed
        };

        onboardingViewed.value = userData.viewed;
        console.log('onboardingViewed:', onboardingViewed.value);

        waitingForOnboarding.value = false;
        console.log('waitingForOnboarding:', waitingForOnboarding.value);

        if (onboardingViewed.value === false) {
            showCarousel.value = true;
            console.log('Show Carousel:', showCarousel.value);
        }
        
        sessionStorage.setItem('viewedOnboarding', JSON.stringify(false));
        
        sessionStorage.setItem(`user_${telegramId}`, JSON.stringify(userData));
      } else {
        console.error('Username not found in response:', response.data);
      }
    } catch (error) {
      console.error('Error fetching username:', error);
    }
  };

  const formatPlace = (place: number) => {
    const j = place % 10;
    const k = place % 100;
    if (j == 1 && k != 11) {
      return place + 'st';
    }
    if (j == 2 && k != 12) {
      return place + 'nd';
    }
    if (j == 3 && k != 13) {
      return place + 'rd';
    }
    return place + 'th';
  };

  const fetchLeaderboardData = async (telegramId: string) => {
    try {
      const response = await axios.get(`/api/leaderboard/${telegramId}`);
      if (response.data) {
        points.value = response.data.coins;
        place.value = response.data.rank;
        league.value = response.data.league;
        league_id.value = response.data.league_id;
      } else {
        console.error('No data found in response:', response.data);
      }
    } catch (error) {
      console.error('Error fetching leaderboard data:', error);
    }
  };

  const totalTime = ref(0); // Общее время обратного отсчета в секундах (24 часа)
  const timeLeft = ref(totalTime.value);
  const buttonColor = ref('#858585');
  let interval: any;

  const setTime = (totalSeconds: number, leftSeconds: number) => {
    totalTime.value = totalSeconds;
    timeLeft.value = leftSeconds > 0 ? leftSeconds : 0; // Убедиться, что leftSeconds не отрицателен
  };

  const startCountdown = () => {
    interval = setInterval(() => {
      if (timeLeft.value > 0) {
        timeLeft.value -= 1;
      } else {
        clearInterval(interval); // Останавливаем интервал, если время закончилось
        buttonColor.value = '#e7640b'; // Оранжевый цвет кнопки, если время закончилось
      }
    }, 1000);
  };

  onMounted(() => {
    axios.get('/checkin_time', {
        params: {
            telegram_id: localStorage.getItem('telegram_id') 
        }
      })
      .then(response => {
        const userData = response.data;
        const totalSeconds = userData.total_time;
        const currentSeconds = userData.seconds_since_checkin;

        setTime(totalSeconds, currentSeconds);
        startCountdown(); // Запускаем обратный отсчет

      })
      .catch(error => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
  });

  const checkIn = () => {
    window.location.reload();
  };

  const dashArray = computed(() => {
    const percentage = (timeLeft.value / totalTime.value) * 100;
    const circumference = 2 * Math.PI * 50; // Окружность круга с радиусом 50
    const filled = (percentage / 100) * circumference;
    return `${filled}, ${circumference}`;
  });

  const progressBarWidth = computed(() => {
    return `${(timeLeft.value / totalTime.value) * 100}%`;
  });

  const formattedTime = computed(() => {
    const hours = Math.floor(timeLeft.value / 3600);
    const minutes = Math.floor((timeLeft.value % 3600) / 60);
    const seconds = Math.floor(timeLeft.value % 60);
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  });


return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_Transition, { name: "modal-slide-up" }, {
    default: _withCtx(() => [
      (__props.isVisible)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _cache[3] || (_cache[3] = _createElementVNode("canvas", {
              style: {"display":"none"},
              width: "384",
              height: "590"
            }, null, -1)),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('nearyou')), 1),
                _cache[0] || (_cache[0] = _createElementVNode("div", { class: "hr-close" }, null, -1)),
                _createElementVNode("div", { class: "close" }, [
                  _createElementVNode("img", {
                    class: "close-img",
                    onClick: closeModal,
                    src: _imports_0,
                    alt: "Close"
                  })
                ])
              ])
            ]),
            (placesFound.value)
              ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  (loading.value)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                    : (_openBlock(), _createBlock(ScrollPlace, {
                        key: 1,
                        places: placesRef.value,
                        timeLeft: timeLeft.value,
                        loading: loading.value
                      }, null, 8, ["places", "timeLeft", "loading"]))
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.$t('no_places')), 1)
                ])),
            (timeLeft.value > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("div", {
                        class: "progress",
                        style: _normalizeStyle({ width: progressBarWidth.value })
                      }, _cache[1] || (_cache[1] = [
                        _createElementVNode("img", {
                          src: _imports_1,
                          class: "progress-image"
                        }, null, -1)
                      ]), 4)
                    ]),
                    _createElementVNode("div", _hoisted_11, [
                      _cache[2] || (_cache[2] = _createElementVNode("img", {
                        class: "locked",
                        src: _imports_2,
                        alt: "Locked"
                      }, null, -1)),
                      _createTextVNode(" " + _toDisplayString(formattedTime.value), 1)
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("div", _hoisted_13, [
                _createElementVNode("img", {
                  class: "search-icon",
                  src: require('@/assets/search.png'),
                  alt: "Search"
                }, null, 8, _hoisted_14),
                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t('search')), 1),
                _createElementVNode("p", _hoisted_16, _toDisplayString(_ctx.$t('showing_places')), 1)
              ])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}
}

})