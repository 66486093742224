import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"
import _imports_0 from '@/assets/leagueCoin.png'
import _imports_1 from '@/assets/strokeGrey.png'
import _imports_2 from '@/assets/checked.png'


const _hoisted_1 = { class: "task-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "task-info-container" }
const _hoisted_4 = { class: "task-info" }
const _hoisted_5 = { class: "task-name" }
const _hoisted_6 = { class: "task-effect" }
const _hoisted_7 = { class: "price-container" }
const _hoisted_8 = {
  key: 0,
  class: "coin",
  src: _imports_0,
  alt: "Coin"
}
const _hoisted_9 = {
  key: 1,
  class: "task-price"
}
const _hoisted_10 = {
  key: 2,
  class: "task-price"
}
const _hoisted_11 = {
  key: 0,
  class: "stroke-img",
  src: _imports_1,
  alt: "stroke"
}
const _hoisted_12 = {
  key: 1,
  class: "loading-ring"
}
const _hoisted_13 = {
  key: 3,
  src: _imports_2,
  class: "checked",
  alt: "Completed"
}

import { computed, ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { getLocalizedText } from '@/localizatoin';

interface Task {
  id: number;
  name: string;
  description: string;
  reward: number;
  link: string;
  completed: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'TaskComponent',
  props: {
    task: {}
  },
  setup(__props: any) {

const props = __props;

const getTaskImage = computed(() => {
  return require('@/assets/task.png');
});
const completed = computed(() => {
  return getLocalizedText('completed');
})

const claim = computed(() => {
  return getLocalizedText('claim');
})

const taskCompleted = ref(props.task.completed);
const loading = ref(false);
const showClaimButton = ref(false);

const getLocalizedName = computed(() => {
  if (props.task.name === 'Follow us on X') {
        return getLocalizedText('task_name_x');
      } else if (props.task.name === 'Follow us on Telegram') {
        return getLocalizedText('task_name_tg');
      } else {
        // Если тип буста не распознан, используем оригинальное описание
        return props.task.name;
      }
})

const getLocalizedEffect = computed(() => {
  if (props.task.name === 'Follow us on X') {
        return getLocalizedText('task_name_x_effect');
      } else if (props.task.name === 'Follow us on Telegram') {
        return getLocalizedText('task_name_tg_effect');
      } else {
        // Если тип буста не распознан, используем оригинальное описание
        return props.task.description;
      }
})

const claimReward = async () => {
  try {
    const telegramId = localStorage.getItem('telegram_id');
    if (!telegramId) {
      console.error('Telegram ID not found in local storage');
      return;
    }

    await axios.get('/api/get-reward', {
      params: {
        reward: props.task.reward,
        telegram_id: telegramId,
        task_id: props.task.id
      }
    });

    taskCompleted.value = true;
    showClaimButton.value = false;  // Скрыть кнопку "Claim"
    loading.value = false;  // Убрать загрузочное кольцо, если оно ещё активно
    sessionStorage.setItem(`task_${props.task.id}_completed`, 'true');
  } catch (error) {
    console.error('Error claiming reward:', error);
  }
};

const handleTaskClick = async () => {
  // Если задача уже завершена, действия не требуются
  if (taskCompleted.value) {
    return;
  }

  // Если кнопка "Claim" видима, вызываем функцию claimReward
  if (showClaimButton.value) {
    await claimReward();
    return;
  }

  // Если кнопка "Claim" не видима и загрузка не идет, начинаем загрузку и показываем кнопку
  if (!loading.value) {
    loading.value = true; // Активируем загрузочное кольцо
    sessionStorage.setItem(`task_${props.task.id}_loading`, 'true');
    if (window.Telegram && window.Telegram.WebApp) {
      window.Telegram.WebApp.openLink(props.task.link);
    } else {
      window.open(props.task.link, '_blank');
    }

    // Задержка перед отображением кнопки "Claim"
    setTimeout(() => {
      loading.value = false;
      showClaimButton.value = true; // Показываем кнопку "Claim" после 10 секунд
      sessionStorage.setItem(`task_${props.task.id}_loading`, 'false');
      sessionStorage.setItem(`task_${props.task.id}_showClaimButton`, 'true');
    }, 10000);
  }
};

// Восстановление состояния при загрузке страницы
const restoreState = () => {
  if (sessionStorage.getItem(`task_${props.task.id}_completed`) === 'true') {
    taskCompleted.value = true;
  }

  if (sessionStorage.getItem(`task_${props.task.id}_loading`) === 'true') {
    loading.value = true;
  }

  if (sessionStorage.getItem(`task_${props.task.id}_showClaimButton`) === 'true') {
    showClaimButton.value = true;
  }
};

// Восстановление состояния при загрузке страницы
restoreState();

watch(() => document.visibilityState, (newState) => {
  if (newState === 'visible') {
    restoreState();
  }
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['task', { completed: taskCompleted.value }]),
    onClick: handleTaskClick
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("img", {
        class: "task-image",
        src: getTaskImage.value,
        alt: "task"
      }, null, 8, _hoisted_2),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h3", _hoisted_5, _toDisplayString(getLocalizedName.value), 1),
          _createElementVNode("p", _hoisted_6, _toDisplayString(getLocalizedEffect.value), 1),
          _createElementVNode("div", _hoisted_7, [
            (!taskCompleted.value)
              ? (_openBlock(), _createElementBlock("img", _hoisted_8))
              : _createCommentVNode("", true),
            (taskCompleted.value)
              ? (_openBlock(), _createElementBlock("p", _hoisted_9, _toDisplayString(completed.value), 1))
              : (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.task.reward), 1))
          ])
        ]),
        (!loading.value && !showClaimButton.value && !taskCompleted.value)
          ? (_openBlock(), _createElementBlock("img", _hoisted_11))
          : _createCommentVNode("", true),
        (loading.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12))
          : _createCommentVNode("", true),
        (showClaimButton.value)
          ? (_openBlock(), _createElementBlock("button", {
              key: 2,
              class: "claim",
              onClick: _withModifiers(claimReward, ["stop","prevent"])
            }, _toDisplayString(claim.value), 1))
          : _createCommentVNode("", true),
        (taskCompleted.value)
          ? (_openBlock(), _createElementBlock("img", _hoisted_13))
          : _createCommentVNode("", true)
      ])
    ])
  ], 2))
}
}

})