<template>
  <TonConnectUIProvider>
    <div class="app">
        <router-view />
      <NavigationPanel v-if="showNavigationPanel" />
    </div>
  </TonConnectUIProvider>
</template>

<script scoped lang="ts">
import { defineComponent, computed, onMounted, ref, provide } from 'vue';
import { useRoute } from 'vue-router';
import NavigationPanel from "./components/NavigationPanel.vue";
import TonConnectUIProvider from './components/TonConnectUIProvider.vue';

export default defineComponent({
  name: "App",
  components: {
    NavigationPanel,
    TonConnectUIProvider
  },
  setup() {
    const route = useRoute();
    const showNavigationPanel = computed(() => 
      route.name !== 'LoginPage' && 
      route.name !== 'LeaguePage' && 
      route.name !== 'Main'
    );

    // Провайдим флаг для checkin-компонентов
    const isCheckinComponentVisible = ref(false);
    provide('isCheckinComponentVisible', isCheckinComponentVisible);

    // Создаем ref для глобального экземпляра карты
    // Этот объект будет храниться в Pinia-стиле для повторного использования
    const mapInstance = ref(null);
    provide('map', mapInstance);

    onMounted(() => {
      const appElement = document.querySelector('.app');
      if (appElement) {
        appElement.classList.add('mobile-wrap');
      }

      // Пример работы с Telegram SDK
      if ((window as any).telegramApps && (window as any).telegramApps.sdk) {
        const { retrieveLaunchParams, postEvent } = (window as any).telegramApps.sdk;
        try {
          const lp = retrieveLaunchParams();
          if (lp && !['macos', 'tdesktop', 'weba', 'web', 'webk'].includes(lp.platform)) {
            postEvent('web_app_expand');
            document.body.classList.add('mobile-body');
          }
        } catch (error) {
          console.warn('Не удалось получить параметры запуска:', error);
        }
      } else {
        console.warn('Telegram SDK не найден. Приложение не запущено внутри Telegram Web App.');
      }
    });

    return {
      showNavigationPanel
    };
  }
});
</script>
<style>

.mk-logo-control, .mk-legal {
  display: none;
  
}

.custom-cluster {
  width: 32px;
  height: 32px;
  background-color: #2990FF;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid white;
  border-radius: 50%;
  font-weight: bold;
  position: relative;
}

.custom-cluster .text {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1;
}

.custom-callout-bonus-merged{
  border-radius: 24px;
  background: #FF8500;
  border: 2px solid #FDB900;
  font-size: 11px;
  font-weight: 590;
  color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
}

.custom-callout-bonus {
  bottom: 10px;
  position: absolute;
  right: 0;
  border-radius: 24px;
  background: #cb6a01;
  border: 2px solid #FDB900;
  font-size: 11px;
  font-weight: 590;
  color: aliceblue;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

* {
  font-family: Arial, Helvetica, sans-serif;
  box-sizing: border-box;
}

body {
  background: #0F0F0F;
  overflow: hidden;
}

.mobile-body {
  overflow: hidden;
  height: 100vh;
}

.place-name-checkin{
  border-radius: 8px;
  color: aliceblue;
  font-size: 11px;
  background: rgba(39, 37, 37, 1);
  border: 1px solid rgba(56, 54, 54, 1);
  padding: 3px 4px;
  max-width: 70px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.custom-annotation-checkin {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.custom-callout {
  background: radial-gradient(50% 50% at 50% 50%, rgba(0, 122, 255, 0.2) 55.5%, rgba(0, 122, 255, 0.64) 85.5%, #007AFF 100%);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease;
}

.custom-callout-mayor {
  background: radial-gradient(50% 50% at 50% 50%, rgba(255, 133, 0, 0) 0%, rgba(255, 133, 0, 0.16) 41.67%, rgba(255, 133, 0, 0.36) 71.67%, rgba(255, 133, 0, 0.6) 85.67%, #FF8500 100%);
  border-radius: 50%;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: transform 0.3s ease;
}

.custom-callout {
  /* Существующие стили */
  z-index: 1000; /* Добавьте эту строку */
}

.custom-annotation {
  /* Существующие стили */
  z-index: 999; /* Добавьте эту строку */
}

.custom-callout-img {
  width: 50px;
}

.custom-callout-message {
  position: absolute;

  min-width: 170px;
  height: auto;
  background: linear-gradient(0deg, #007AFF, #007AFF),
  linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%);
  border: 1.5px solid #FFFFFF33;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  rotate: -15deg;
  font-size: 13px;
  font-weight: 590;
  color: #FFFFFF;
}

.custom-callout-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #007aff;
}

.custom-callout-arrow-orange, .custom-callout-arrow-purple {
  position: absolute;
  width: 12px;
  height: 6px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #FDB900;
}

.custom-callout-arrow-purple {
  border-top: 10px solid #672F54;
}

.custom-callout-message-mayor {
  height: auto;
  background: #FDB900;
  border-radius: 18px;
  color: #000000CC;
  bottom: -5px;
  width: auto;
  padding: 2px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap; /* Запрещаем перенос текста */
  overflow: hidden; /* Скрываем переполнение */
  text-overflow: ellipsis; /* Добавляем многоточие, если текст не помещается */
  font-size: 10px;
  font-weight: 500;
  max-width: 120px;
}

.circle-element {
  border-radius: 50%;
  width: 10px;
  position: absolute;
  height: 10px;
  border: 1.5px solid #222;
  top: 7px;
  right: 7px;
  background: #FDB900; 
}

.custom-annotation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.custom-annotation .place-name {
  background: #672F54;
  border-radius: 18px;
  color: #f0f8ff;
  padding: 3px 4px;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.mobile-wrap {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: #0F0F0F;
}
</style>
