import { defineStore } from 'pinia';
import axios from 'axios';

// Интерфейсы для типизации данных
interface Referral {
  telegram_id: string;
  username: string;
  photo?: string;
  coins: number;
  league_name: string;
  rank: number;
}

interface ReferralsResponse {
  referrals: Referral[];
  ref_bonus: number;
}

export const useFriendsStore = defineStore('friends', {
  state: () => ({
    referrals: [] as Referral[],
    refBonus: 0,
    isLoading: false,
    error: null as string | null
  }),
  
  getters: {
    formattedRefBonus: (state): string => {
      return formatNumber(state.refBonus);
    }
  },
  
  actions: {
    async fetchReferrals(): Promise<void> {
      const telegramId = localStorage.getItem('telegram_id');
      if (!telegramId) {
        this.error = 'Не найден telegram_id в localStorage';
        console.error(this.error);
        return;
      }
      
      this.isLoading = true;
      this.error = null;
      
      try {
        const response = await axios.get<ReferralsResponse>(`/api/get_referrals`, { 
          params: { telegram_id: telegramId } 
        });
        
        if (response.status === 200) {
          this.referrals = response.data.referrals;
          this.refBonus = response.data.ref_bonus;
        } else {
          this.error = 'Ошибка при получении списка рефералов';
          console.error(this.error, response.data);
        }
      } catch (error) {
        this.error = 'Ошибка при выполнении запроса';
        console.error('Ошибка при получении рефералов:', error);
      } finally {
        this.isLoading = false;
      }
    },
    
    async claimBonus(): Promise<boolean> {
      const telegramId = localStorage.getItem('telegram_id');
      if (!telegramId) {
        this.error = 'Не найден telegram_id в localStorage';
        console.error(this.error);
        return false;
      }
      
      this.isLoading = true;
      this.error = null;
      
      try {
        const response = await axios.post(`/api/claim_referral`, { 
          telegram_id: telegramId 
        });
        
        if (response.status === 200) {
          console.log('Монеты получены успешно');
          this.refBonus = 0;
          return true;
        } else {
          this.error = 'Ошибка при получении монет';
          console.error(this.error);
          return false;
        }
      } catch (error) {
        this.error = 'Ошибка при выполнении запроса';
        console.error('Ошибка при получении монет:', error);
        return false;
      } finally {
        this.isLoading = false;
      }
    }
  }
});

// Вспомогательная функция для форматирования чисел
function formatNumber(number: number): string {
  if (typeof number !== 'number') {
    console.error('formatNumber получил не числовое значение:', number);
    return String(number);
  }
  return number.toLocaleString('en-US');
}