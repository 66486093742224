// src/stores/tasksStore.ts
import { defineStore } from 'pinia';
import { ref } from 'vue';
import axios from 'axios';

export interface Task {
  id: number;
  name: string;
  link: string;
  reward: number;
  description: string;
  completed: boolean;
}

export const useTasksStore = defineStore('tasks', () => {
  const tasks = ref<Task[]>([]);

  const fetchTasks = async () => {
    const telegramId = localStorage.getItem('telegram_id');
    if (!telegramId) {
      console.error('Telegram ID не найден в localStorage');
      return;
    }

    try {
      const response = await axios.get('/api/tasks', { params: { telegram_id: telegramId } });
      tasks.value = response.data;
      console.log('Задачи получены:', tasks.value);
    } catch (error) {
      console.error('Ошибка получения задач:', error);
    }
  };

  return {
    tasks,
    fetchTasks,
  };
});
