<template>
  <div class="navigation-panel">
    <div class="navigation">

      <router-link
        to="/visited"
        id="visited"
        class="nav-button"
        :class="{ active: isActive === 'visited' }"
        @click="setActive('visited')"
        style="text-decoration: none;"
      >
        <div class="link-button">
          <img :src="isActive === 'visited' ? visitedFilled : visited" alt="Посещенные места" />
          <p class="link-name" :style="isActive === 'visited' ? 'color: #0087E9; text-decoration: none;' : 'text-decoration: none;'">{{ $t('visited') }}</p>
        </div>
      </router-link>

      <router-link
        to="/referrals"
        class="nav-button"
        id="frens"
        :class="{ active: isActive === 'referrals' }"
        @click="setActive('referrals')"
        style="text-decoration: none;"
      >
        <div class="link-button">
          <img :src="isActive === 'referrals' ? friendsFilled : friends" alt="Друзья" />
          <p class="link-name" :style="isActive === 'referrals' ? 'color: #0087E9; text-decoration: none;' : 'text-decoration: none;'">{{ $t('frens') }}</p>
        </div>
      </router-link>

      <router-link
        to="/discovery"
        id="discovery"
        class="nav-button"
        @click.prevent="handleDiscoveryClick"
        :class="{ active: isActive === 'discovery' }"
        style="text-decoration: none;"
      >
      <div :class="['circle-checkin', {'active-timer': timeLeft > 0}]">
        <div v-if="timeLeft > 0" class="checkin-timer">
          <img class="timer-img" :src="require(`@/assets/panel/timer.png`)" alt="Timer" />
          {{ formattedTime }}</div>
        <img v-else class="checkin-img" :src="require(`@/assets/panel/checkin_black.png`)" alt="checkin" />
        <div v-if="timeLeft > 0" class="circle-background" :style="circleStyle"></div>
      </div>
      </router-link>

      <router-link
        to="/boost"
        class="nav-button"
        id="boost"
        :class="{ active: isActive === 'boost' }"
        @click="setActive('boost')"
        style="text-decoration: none;"
      >
        <div class="link-button">
          <img :src="isActive === 'boost' ? boostFilled : boost" alt="Буст" />
          <p class="link-name" :style="isActive === 'boost' ? 'color: #0087E9; text-decoration: none;' : 'text-decoration: none;'">{{ $t('boost') }}</p>
        </div>
      </router-link>

      <div
        class="nav-button"
        id="account"
        :class="{ active: isActive === 'account' }"
        style="text-decoration: none;"
      >
        <div class="link-button">
          <div class="circle-account">{{ firstLetter }}</div>
          <p class="link-name" :style="isActive === 'account' ? 'color: #0087E9; text-decoration: none;' : 'text-decoration: none;'">{{ $t('account') }}</p>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { ref, watchEffect, computed, inject, onMounted } from 'vue';
import axios from 'axios';
import { useRoute, useRouter } from 'vue-router';
import checkin from '@/assets/panel/checkin.png';
import checkinFilled from '@/assets/panel/checkin_filled.png';
import visited from '@/assets/panel/visited.png';
import visitedFilled from '@/assets/panel/visited_filled.png';
import friends from '@/assets/panel/friends.png';
import friendsFilled from '@/assets/panel/friends_filled.png';
import discovery from '@/assets/panel/discovery.png';
import discoveryFilled from '@/assets/panel/discovery_filled.png';
import boost from '@/assets/panel/boost.png';
import boostFilled from '@/assets/panel/boost_filled.png';
import account from '@/assets/panel/account.png';
import accountFilled from '@/assets/panel/account_filled.png';

const isActive = ref('');

const totalTime = ref(0); // Общее время обратного отсчета в секундах (24 часа)
const timeLeft = ref(totalTime.value);
const buttonColor = ref('#858585');
let interval;

const progressBarWidth = computed(() => {
    return `${(timeLeft.value / totalTime.value) * 100}%`;
  });

const formattedTime = computed(() => {
  const hours = Math.floor(timeLeft.value / 3600);
  const minutes = Math.floor((timeLeft.value % 3600) / 60);
  const seconds = Math.floor(timeLeft.value % 60);

  let result = '';
  if (hours > 0) {
    result += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) { // include minutes if there are hours
    result += `${minutes}m `;
  }
  result += `${seconds}s`;

  return result.trim(); // remove any trailing space
});

const firstLetter = computed(() => {
  const username = localStorage.getItem('username');
  return username ? username.charAt(0).toUpperCase() : '';
});

const circleStyle = computed(() => {
  const percentage = (timeLeft.value / totalTime.value) * 100;
  return {
    background: `conic-gradient(
      #0000003D ${percentage}%, 
      #444444 ${percentage}%
    )`
  };
});

const startCountdown = () => {
  interval = setInterval(() => {
    if (timeLeft.value > 0) {
      timeLeft.value -= 1;
    } else {
      clearInterval(interval); // Останавливаем интервал, если время закончилось
      buttonColor.value = '#e7640b'; // Оранжевый цвет кнопки, если время закончилось
    }
  }, 1000);
};

const previousActive = ref('');
const route = useRoute();
const router = useRouter();
const isCheckinComponentVisible = inject('isCheckinComponentVisible');

function showCheckinComponent() {
  isCheckinComponentVisible.value = true;
}

function hideCheckinComponent() {
  isCheckinComponentVisible.value = false;
}

const setActive = (page) => {
  isActive.value = page;
  triggerHapticFeedback();
};

function handleDiscoveryClick() {
  if (isActive.value === 'discovery' && timeLeft.value <= 0) {
    showCheckinComponent();
  } else {
    hideCheckinComponent();
    setActive('discovery');
  }
}

const triggerHapticFeedback = () => {
  if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.HapticFeedback) {
    window.Telegram.WebApp.HapticFeedback.impactOccurred("medium");
  } else {
    console.warn("Haptic feedback is not supported or WebApp.HapticFeedback is not available.");
  }
};

const setTime = (totalSeconds, leftSeconds) => {
    totalTime.value = totalSeconds;
    timeLeft.value = leftSeconds > 0 ? leftSeconds : 0; // Убедиться, что leftSeconds не отрицателен
  };

onMounted(() => {
    const hasVisited = localStorage.getItem('hasVisitedDiscovery');
    if (!hasVisited) {
      showCheckinComponent();
      localStorage.setItem('hasVisitedDiscovery', 'true');
    }
    axios.get('/checkin_time', {
        params: {
            telegram_id: localStorage.getItem('telegram_id') 
        }
      })
      .then(response => {
        const userData = response.data;
        const totalSeconds = userData.total_time;
        const currentSeconds = userData.seconds_since_checkin;

        setTime(totalSeconds, currentSeconds);
        startCountdown(); // Запускаем обратный отсчет

      })
      .catch(error => {
        console.error('Ошибка при получении данных пользователя:', error);
      });
});

watchEffect(() => {
  if (route.path === '/boost') {
    isActive.value = 'boost';
  } else if (route.path === '/referrals') {
    isActive.value = 'referrals';
  } else if (route.path === '/visited') {
    isActive.value = 'visited';
  } else if (route.path === '/discovery') {
    isActive.value = 'discovery';
  }
});
</script>

<style scoped>

.timer-img {
  width: 16.67px !important;
  height: 16.67px !important;
}

.checkin-timer {
  color: white;
  font-size: 10px;
  font-weight: 590;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 0px;
  gap: 3.67px;
}

.circle-checkin.active-timer {
  background: rgba(68, 68, 68, 1);
}

.circle-background {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  position: absolute;
}

.circle-account {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #bbbbbb;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.circle-checkin {
  width: 66px;
  height: 66px;
  border-radius: 50%;
  background: #FDB900;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-bottom: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkin-img {
  width: 27.5px !important;
  height: 35.59px !important;
}

#frens img {
  width: 22px;
  height: 16px;
}

.link-name {
  margin: 0;
  color: #bbbbbb;
  text-decoration: none;
  outline: none;
  font-size: 10px;
  font-weight: 600;
}

.link-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.navigation {
  width: 100%;
  height: 80px;
  background: none;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-radius: 6px;
  margin-bottom: 15px;
}

.navigation-panel {
  width: 100%;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  height: 104px;
  background: var(--Custom-surface_primary, #171717F2);
}

.nav-button {
  background: none;
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80px;
}

.nav-button img {
  transition: .5s;
  z-index: 1;
}

#map img {
  width: 17px;
  height: 21px;
}

#visited img {
  width: 22px;
  height: 20px;
}

#discovery img {
  width: 22px;
  height: 22px;
}

#discovery {
  width: 66px;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

#boost img {
  width: 24.5px;
  height: 24.5px;
}

</style>
