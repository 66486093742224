<template>
  <transition name="modal-slide-up">
    <div v-if="isVisible" class="boost-modal-overlay" @click="closeModal">
      <div class="boost-modal-content" 
           @click.stop 
           @mousedown="startDrag"
           @mousemove="onDrag"
           @mouseup="endDrag"
           :style="{ transform: `translateY(${translateY}px)` }">
        <img class="close" :src="require(`@/assets/close-modal.png`)" @click="closeModal">
        <img class="boost-image" :src="getBoostImage" alt="Boost" />
        <h2 class="boost-name">{{ localizedBoostNameWithStat }}</h2>
        <p class="boost-effect">{{ localizedBoostEffect }}</p>
        <div class="price-container">
          <img class="coin" v-if="!isMaxLevel" src="@/assets/leagueCoin.png" alt="Coin" />
          <p class="boost-price">{{ localizedPriceText }}</p>
        </div>
        <button
          :class="{ disabled: isMaxLevel || !hasEnoughCoins }"
          @click="emitBuyBoost"
          :disabled="isMaxLevel || !hasEnoughCoins"
        >
          {{ localizedButtonText }}
        </button>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { getLocalizedText } from '@/localizatoin';
import { defineComponent, computed, PropType, ref } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'BoostModal',
  props: {
    boost: {
      type: Object as PropType<{
        id: number;
        name: string;
        first_level_price: number;
        description: string;
        max_level: number;
        currentLevel: number;
        effect: string;
      }>,
      required: true,
    },
    isVisible: {
      type: Boolean,
      required: true,
    },
    nextLevelPrice: {
      type: Number,
      required: true,
    },
  },
  emits: ['close-modal', 'buy-boost'],
  setup(props, { emit }) {

    const isVisible = ref(true);  
    const dragging = ref(false);
    const startY = ref(0);
    const translateY = ref(0);
    const threshold = 50; // Порог в пикселях для активации закрытия

    const startDrag = (event: { clientY: number; preventDefault: () => void; }) => {
      dragging.value = true;
      startY.value = event.clientY;
      event.preventDefault();
    };

    const localizedBoostNameWithStat = computed(() => {
      let boostName = '';
      let boostStat = '';

      if (props.boost.name === 'Check-in timer') {
        boostName = getLocalizedText('boost_checkin_timer');
        const times = [20, 15, 10, 5];
        boostStat = getLocalizedText('boost_stat_min').replace('{time}', times[props.boost.currentLevel].toString());
      } else if (props.boost.name === 'Traveller') {
        boostName = getLocalizedText('boost_traveller');
        const multipliers = [1.25, 1.5, 2, 2.5];
        boostStat = getLocalizedText('boost_stat_multiplier').replace('{multiplier}', multipliers[props.boost.currentLevel].toString());
      } else {
        boostName = props.boost.name;
        boostStat = props.boost.description;
      }

      return `${boostName} / ${boostStat}`;
    });

    const localizedBoostEffect = computed(() => {
      if (props.boost.name === 'Traveller') {
        return getLocalizedText('boost_effect_checkin_score');
      } else if (props.boost.name === 'Check-in timer') {
        return getLocalizedText('boost_effect_decrease_time');
      } else {
        return props.boost.effect;
      }
    });

    const localizedPriceText = computed(() => {
      if (isMaxLevel.value) {
        return getLocalizedText('max_level');
      } else {
        return getLocalizedText('boost_price')
              .replace('{price}', props.nextLevelPrice.toString())
              .replace('{level}', (props.boost.currentLevel + 1).toString());
      }
    });

    const localizedButtonText = computed(() => {
      if (isMaxLevel.value) {
        return getLocalizedText('maximum_level');
      } else {
        return getLocalizedText('buy_for_coins').replace('{price}', props.nextLevelPrice.toString());
      }
    });

    const onDrag = (event: { clientY: any; }) => {
      if (!dragging.value) return;
      const currentY = event.clientY;
      translateY.value = currentY - startY.value;
    };

    const endDrag = () => {
      dragging.value = false;
      // Проверка, превышен ли порог и следует ли закрывать модальное окно
      if (translateY.value > threshold) {
        // Плавное скрытие модального окна
        smoothClose();
      } else {
        // Возвращение модального окна в исходное положение
        translateY.value = 0;
      }
    };

    const smoothClose = () => {
      const animationDuration = 300; // Продолжительность анимации в мс
      const frameRate = 10; // Частота обновления анимации
      const totalFrames = animationDuration / frameRate;
      let frame = 0;
      const initialY = translateY.value;
      const finalY = 200; // Конечное смещение для закрытия

      const animationInterval = setInterval(() => {
        frame++;
        const progress = frame / totalFrames;
        translateY.value = initialY + (finalY - initialY) * progress;
        if (frame === totalFrames) {
          clearInterval(animationInterval);
          isVisible.value = false; // Скрыть модальное окно после завершения анимации
        }
      }, frameRate);
    };
    
    const router = useRouter()

    const getBoostImage = computed(() => {
      return props.boost.name === 'Check-in timer' ? require('@/assets/clock.png') : require('@/assets/traveller.png');
    });

    const isMaxLevel = computed(() => {
      return props.boost.currentLevel >= props.boost.max_level;
    });

    const priceText = computed(() => {
      return isMaxLevel.value ? 'MAX' : `${props.nextLevelPrice} for L${props.boost.currentLevel + 1}`;
    });

    const hasEnoughCoins = computed(() => {
      const coins = parseInt(localStorage.getItem('coins') || '0');
      return coins >= props.nextLevelPrice;
    });

    const emitBuyBoost = () => {
      emit('buy-boost', props.boost);
    };

    const closeModal = () => {
      emit('close-modal');
    };

    const boostStat = computed(() => {
        if (props.boost.name === 'Check-in timer') {
          switch (props.boost.currentLevel) {
            case 0:
              return ' / 20 min';
            case 1:
              return ' / 15 min';
            case 2:
              return ' / 10 min';
            case 3:
              return ' / 5 min';
            default:
              return '';
          }
        } else if (props.boost.name === 'Traveller') {
          switch (props.boost.currentLevel) {
            case 0:
              return ' / 1.25x';
            case 1:
              return ' / 1.5x';
            case 2:
              return ' / 2x';
            case 3:
              return ' / 2.5x';
            default:
              return '';
          }
        }
        return props.boost.description;
      });

    return {
      getBoostImage,
      isMaxLevel,
      priceText,
      hasEnoughCoins,
      emitBuyBoost,
      closeModal,
      boostStat,
      startDrag,
      onDrag,
      endDrag,
      translateY,
      localizedBoostNameWithStat,
      localizedBoostEffect,
      localizedPriceText,
      localizedButtonText,
    };
  },
});
</script>
  
  <style scoped>

  .swipe-close {
    width: 36px;
    height: 4px;
    gap: 0px;
    border-radius: 4px 0px 0px 0px;
    background: var(--Custom-divider, #FFFFFF0D);
    margin-top: 14px;
    position: absolute;
    left: auto;
    right: auto;
    top: 0;
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 16px;
    width: 28px;
    height: 28px;
  }

  .boost-price{
    margin: 0px;
  }

  .coin {
    width: 20px;
    height: 20px;
  }
  
  .price-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    color: var(--Native-text_color, #FFFFFF);
    gap: 4px;
    margin-top: 16px;
  }
  
  .boost-effect{
    font-size: 16px;
    color: var(--Native-subtitle_text_color, #AAAAAA);
    font-weight: 400;
    margin: 0px;
  }
  
  .boost-name{
    font-size: 20px;
    font-weight: 590;
    color: var(--Native-text_color, #FFFFFF);
    margin-top: 16px;
    margin-bottom: 0px;
  }

  .boost-image {
    width: 140px;
    height: 140px;
    margin-top: 60px;
  }

  .boost-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    transition: opacity 0.3s ease;
    z-index: 2;
  }
  
  .boost-modal-content {
    background: var(--Custom-surface_primary, #171717F2);
    width: 100%;
    height: 475px;
    padding: 20px;
    border-radius: 16px 16px 0 0;
    text-align: center;
    color: aliceblue;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .modal-slide-up-enter-active,
  .modal-slide-up-leave-active {
    transition: all 0.3s ease;
  }
  
  .modal-slide-up-enter,
  .modal-slide-up-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
  
  .description {
    margin-top: auto;
  }
  
  button {
    margin-top: 44px;
    background: var(--Native-button_color, #2990FF);
    color: var(--Native-button_text_color, #FFFFFF);
    outline: none;
    border: none;
    border-radius: 10px;
    width: 100%;
    height: 72px;
    font-size: 17px;
    box-shadow: 0px 4px 14px 0px #2990FF78;
    border: 1px solid;
    border-image-source: linear-gradient(180deg, #75B7FF 0%, rgba(117, 183, 255, 0) 100%);
  }
  
  button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  </style>
  