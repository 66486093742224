// Определяем типы для словарей
interface Dictionary {
  checkinMessage: string;
  boost_checkins: string;
  showing_places: string;
  search: string;
  check_in: string;
  league_Platinum: string;
  league_Gold: string;
  league_Silver: string;
  league_Bronze: string;
  check_ins: string;
  visited: string;
  frens: string;
  discovery: string;
  claim: string;
  my_frens: string;
  cashback: string;
  invite: string;
  addpin: string;
  rate: string;
  first_checkin: string;
  league_bonus: string;
  streak: string;
  streak_bonus: string;
  review_bonus: string;
  traveller: string;
  close: string;
  boost: string;
  your_balance: string;
  subscribe_x: string;
  subscribe_x_descrp: string;
  subscribe_tg: string;
  subscribe_tg_descrp: string;
  pinstay: string;
  next: string;
  cancel: string;
  leave_message: string;
  boost_checkin_timer: string;
  boost_traveller: string;
  boost_stat_min: string;
  boost_stat_multiplier: string;
  boost_effect_checkin_score: string;
  boost_effect_decrease_time: string;
  for_l: string;
  max_level: string;
  maximum_level: string;
  buy_for_coins: string;
  boost_price: string;
  task_name_tg: string;
  task_name_x: string;
  task_name_tg_effect: string;
  task_name_x_effect: string;
  completed: string;
  league: string;
  no_places: string;
  share_to_story: string;
  boostIcon: string;
  account: string;
  nearyou: string;
  message: string;
  mayorMessage: string;
  congrats: string;
  mayor_message: string;
  great_close: string;
  mayor_message_previous: string;
  details: string;
  currentMayorMessage: string;
  visitsCount: string;
  make_checkins: string;
}

interface Dictionaries {
  en: Dictionary;
  ru: Dictionary;
}

// Словари для различных языков
const dictionaries: Dictionaries = {
  en: {
    boost_checkins: 'Boost your check-ins',
    showing_places: 'Only showing places in radius of 150 m',
    search: 'Search',
    check_in: 'Check-in',
    league_Platinum: 'Platinum',
    league_Gold: 'Gold',
    league_Silver: 'Silver',
    league_Bronze: 'Bronze',
    check_ins: 'checkins',
    visited: 'Visited',
    frens: 'Frens',
    discovery: 'Discovery',
    claim: 'Claim',
    my_frens: 'MY FRENS',
    cashback: 'Every time your fren checkins, you get 10% cashback',
    invite: 'Invite more frens',
    addpin: 'Add a pin',
    rate: 'Rate this place for extra coins:',
    first_checkin: 'First check in of the day',
    league_bonus: 'League bonus',
    streak: 'Streak',
    streak_bonus: 'Streak bonus',
    review_bonus: 'Review Bonus',
    traveller: 'Traveller',
    close: 'Close',
    boost: 'Boost',
    your_balance: 'Your balance:',
    subscribe_x: 'Follow us on X',
    subscribe_x_descrp: 'Follow us on X for the latest updates and exclusive content!',
    subscribe_tg: 'Follow us on Telegram',
    subscribe_tg_descrp: 'Stay connected with us on Telegram for the latest news and exclusive content!',
    pinstay: 'Your pin will stay for 7 days. Let the world see!',
    next: 'Next',
    cancel: 'Cancel',
    leave_message: 'Leave the message',
    boost_checkin_timer: 'Check-in timer',
    boost_traveller: 'Traveller',
    boost_stat_min: '{time} min',
    boost_stat_multiplier: '{multiplier}x',
    boost_effect_checkin_score: 'Increases the checkin scores',
    boost_effect_decrease_time: 'Decreases the time between checkins',
    for_l: ' for L',
    max_level: 'MAX',
    maximum_level: 'Maximum level',
    buy_for_coins: 'Buy for {price} coins',
    boost_price: '{price} for L{level}',
    task_name_tg: 'Follow us on Telegram',
    task_name_x: 'Follow us on X',
    task_name_tg_effect: 'Stay connecnted with us on Telegram for the latest news and exclusive content!',
    task_name_x_effect: 'Follow us on X for the latest updates and exclusive content!',
    completed: 'Completed',
    league: 'league',
    no_places: 'No places found around you :(',
    share_to_story: 'Share to story',
    checkinMessage: 'I checked in at {placeName} and earned {bonusCoins} coins! Join Checkiton and compete with me! @checkitonbot',
    boostIcon: 'Boost',
    account: 'Profile',
    nearyou: 'Near you',
    message: 'Next check-in available after the timer expires',
    mayorMessage: "You are the mayor with {count} checkins",
    congrats: 'Congratulations',
    mayor_message: "You became the mayor of this place",
    great_close: 'Great!',
    mayor_message_previous: 'You just honored @{previous} as the new mayor',
    details: 'DETAILS',
    currentMayorMessage: '@{nickname} is the Mayor',
    visitsCount: 'Number of visits: ',
    make_checkins: `You haven't been anywhere yet, make check in`
  },
  ru: {
    boost_checkins: 'Улучшить чек-ин',
    showing_places: 'Отображаются места в радиусе 150м',
    search: 'Поиск',
    check_in: 'Чек-ин',
    league_Platinum: 'Платина',
    league_Gold: 'Золото',
    league_Silver: 'Серебро',
    league_Bronze: 'Бронза',
    check_ins: 'чекинов',
    visited: 'Посещенные',
    frens: 'Друзья',
    discovery: 'Исследовать',
    claim: 'Забрать',
    my_frens: 'МОИ ДРУЗЬЯ',
    cashback: 'Получай 10% за каждый чек-ин друга',
    invite: 'Пригласить друзей',
    addpin: 'Добавить пин',
    rate: 'Оцените это место чтобы получить больше монет:',
    first_checkin: 'Первый чекин за день',
    league_bonus: 'Бонус лиги',
    streak: 'Серия',
    streak_bonus: 'Бонус за серию',
    review_bonus: 'Бонус за оценку',
    traveller: 'Путешественник',
    close: 'Закрыть',
    boost: 'Буст',
    your_balance: 'Твой баланс',
    subscribe_x: 'Подпишись на X',
    subscribe_x_descrp: 'Подпишись на наш X чтобы быть в курсе последних обновлений!',
    subscribe_tg: 'Подпишись на Телеграм',
    subscribe_tg_descrp: 'Подпишись на наш Телеграм чтобы быть в курсе последних обновлений!',
    pinstay: 'Твой пин будет доступен 7 дней. Пускай весь мир увидит!',
    next: 'Далее',
    cancel: 'Отмена',
    leave_message: 'Оставьте комментарий',
    boost_checkin_timer: 'Таймер чек-ина',
    boost_traveller: 'Путешественник',
    boost_stat_min: '{time} мин',
    boost_stat_multiplier: '{multiplier}x',
    boost_effect_checkin_score: 'Увеличивает очки за чекин',
    boost_effect_decrease_time: 'Уменьшает время между чекинами',
    for_l: ' за уровень ',
    max_level: 'МАКС',
    maximum_level: 'Максимальный уровень',
    buy_for_coins: 'Купить за {price} монет',
    boost_price: '{price} для уровень {level}',
    task_name_tg: 'Подпишись на Телеграмм',
    task_name_x: 'Подпишись на Х',
    task_name_tg_effect: 'Подпишись на наш Телеграм чтобы быть в курсе последних обновлений!',
    task_name_x_effect: 'Подпишись на наш X чтобы быть в курсе последних обновлений!',
    completed: 'Выполнено',
    league: 'лига',
    no_places: 'Рядом с тобой мест не найдено :(',
    share_to_story: 'Добавить в историю',
    checkinMessage: 'Я сделал чекин в {placeName} и получил {bonusCoins} монет! Заходи в Checkiton и соревнуйся со мной! @checkitonbot',
    boostIcon: 'Буст',
    account: 'Профиль',
    nearyou: 'Места рядом',
    message: 'Следующий чекин будет доступен по окончанию таймера',
    mayorMessage: "Ты мэр с {count} {checkinWord}",
    congrats: 'Поздравляем',
    mayor_message: "Ты стал мэром этого места",
    great_close: 'Отлично!',
    mayor_message_previous: 'Ты превзошел @{previous} и стал мэром',
    details: 'ДЕТАЛИ',
    currentMayorMessage: "@{nickname} Мэр",
    visitsCount: 'Кол-во посещений: ',
    make_checkins: 'Вы пока нигде не были, зачекиньтесь'
  }
};

  // Функция для получения локализованного текста
  function getLocalizedText(key: keyof Dictionary, params: Record<string, any> = {}): string {
    const language = localStorage.getItem('user_language') || 'en';
    const dictionary = dictionaries[language as keyof Dictionaries];
  
    if (!dictionary) {
      console.warn(`Dictionary for language '${language}' not found. Using default.`);
      return dictionaries.en[key] || key;
    }
  
    let text = dictionary[key] || key;
  
    // Заменяем параметры в тексте
    for (const [param, value] of Object.entries(params)) {
      text = text.replace(new RegExp(`{${param}}`, 'g'), value);
    }
  
    return text;
  }  

// Добавляем Vue плагин для локализации
export const LocalizationPlugin = {
  install(app: any) {
    app.config.globalProperties.$t = getLocalizedText;
  }
};

export { getLocalizedText };
