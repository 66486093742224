import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, KeepAlive as _KeepAlive, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "container"
}
const _hoisted_2 = { class: "places-container" }
const _hoisted_3 = { class: "place-container" }
const _hoisted_4 = { class: "place-info" }
const _hoisted_5 = { class: "last-checkin" }
const _hoisted_6 = { class: "place-name" }
const _hoisted_7 = { class: "place-address" }
const _hoisted_8 = { class: "place-checkins" }
const _hoisted_9 = {
  key: 1,
  class: "container"
}
const _hoisted_10 = { class: "make-checkin-container" }
const _hoisted_11 = ["src"]
const _hoisted_12 = { class: "make-checkin-text" }

import { computed, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { usePlacesStore } from '../store/placesStore';

const MAP_ELEMENT_ID = 'map';


export default /*@__PURE__*/_defineComponent({
  __name: 'VisitedPlaces',
  setup(__props) {

const router = useRouter();
const placesStore = usePlacesStore();
onMounted(async () => {
  window.Telegram.WebApp.ready();

  // Показываем кнопку "Назад"
  window.Telegram.WebApp.BackButton.show();

  // Обрабатываем клик по кнопке "Назад"
  window.Telegram.WebApp.BackButton.onClick(() => {
    router.push('/discovery');
  });

  const telegramId = window.Telegram.WebApp.initDataUnsafe?.user?.id;
  if (!telegramId) {
    console.error('Не удалось получить telegram ID');
    return;
  }

  try {
    // Получаем токен для карты
    const token = await placesStore.fetchMapKitToken();
  
    // Инициализируем или переиспользуем карту
    placesStore.initMap(token, MAP_ELEMENT_ID);
    
    // Настраиваем взаимодействие с картой
    placesStore.setupMapInteractions(MAP_ELEMENT_ID);

    placesStore.fetchPlaces(telegramId);
  } catch (error) {
    console.error('Ошибка при инициализации карты:', error);
  }
});

const goCheckin = () => {
  router.push('Discovery')
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(placesStore).sortedPlaces)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(), _createBlock(_KeepAlive, null, [
            _cache[0] || (_cache[0] = _createElementVNode("div", { id: "map" }, null, -1))
          ], 1024)),
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(placesStore).sortedPlaces, (place) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "places",
                key: place.id
              }, [
                _createElementVNode("div", _hoisted_3, [
                  _createElementVNode("div", {
                    class: "place-image",
                    style: _normalizeStyle(`background-image: url(${_unref(placesStore).getImageSrc(place.category)})`)
                  }, null, 4),
                  _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, _toDisplayString(place.last_checkin), 1),
                    _createElementVNode("div", _hoisted_6, _toDisplayString(place.name), 1),
                    _createElementVNode("div", _hoisted_7, _toDisplayString(place.address), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(place.count) + " " + _toDisplayString(_ctx.$t('check_ins')), 1),
                    _cache[1] || (_cache[1] = _createElementVNode("hr", null, null, -1))
                  ])
                ])
              ]))
            }), 128))
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("img", {
              class: "make-checkin-img",
              src: require('@/assets/make-checkin.png')
            }, null, 8, _hoisted_11),
            _createElementVNode("p", _hoisted_12, _toDisplayString(_ctx.$t('make_checkins')), 1)
          ]),
          _createElementVNode("button", {
            class: "make-checkin-button",
            onClick: goCheckin
          }, "Check-in")
        ]))
  ]))
}
}

})