// stores/placesStore.ts
import { defineStore } from 'pinia';
import axios from 'axios';
import { markRaw } from 'vue';

// Интерфейсы для типизации
export interface Place {
  id: string;
  name: string;
  category: string;
  latitude: number;
  longitude: number;
  count: number;
  last_checkin: string;
  address: string;
}

export interface MapKit {
  init: (options: any) => void;
  Map: any;
  Coordinate: any;
  Annotation: any;
  ImageAnnotation: any;
  FeatureVisibility: any;
}

export const usePlacesStore = defineStore('places', {
  state: () => ({
    places: [] as Place[],
    map: null as any,
    mapToken: '',
    isLoading: false,
    error: null as string | null,
    isMapInitialized: false // Добавляем флаг инициализации
  }),
  
  getters: {
    sortedPlaces: (state) => {
      return [...state.places].sort(
        (a, b) => new Date(b.last_checkin).getTime() - new Date(a.last_checkin).getTime()
      );
    }
  },
  
  actions: {
    async fetchMapKitToken(): Promise<string> {
      try {
        const response = await fetch('/get-mapkit-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        const data = await response.json();
        this.mapToken = data.token;
        return this.mapToken;
      } catch (error) {
        console.error('Ошибка при получении токена MapKit:', error);
        this.error = 'Не удалось получить токен для карты';
        throw error;
      }
    },
    
    initMap(token: string, mapElementId: string): void {
        console.log('Инициализация MapKit с токеном:', token);
        if (!window.mapkit) {
          console.error('MapKit не загружен');
          this.error = 'MapKit не загружен';
          return;
        }
      
        // Инициализируем MapKit один раз
        if (!this.isMapInitialized) {
          window.mapkit.init({
            authorizationCallback: (done: (token: string) => void) => {
              done(token);
            },
            language: 'ru',
          });
          this.isMapInitialized = true;
          console.log('MapKit успешно инициализирован');
        } else {
          console.log('MapKit уже инициализирован, пропускаем повторную инициализацию');
        }
      
        // Получаем DOM-контейнер для карты по идентификатору
        const container = document.getElementById(mapElementId);
        if (!container) {
          console.error('Контейнер для карты не найден');
          return;
        }
      
        // Если карта еще не создана, создаем её
        if (!this.map) {
          this.map = markRaw(new window.mapkit.Map(container, {
            showsCompass: window.mapkit.FeatureVisibility.Hidden,
            showsZoomControl: window.mapkit.FeatureVisibility.Visible,
            showsMapTypeControl: window.mapkit.FeatureVisibility.Hidden,
            showsScale: window.mapkit.FeatureVisibility.Hidden,
            mapType: window.mapkit.Map.MapTypes.Standard,
            colorScheme: window.mapkit.Map.ColorSchemes.Dark,
            isZoomEnabled: true,
            isScrollEnabled: true,
          }));
      
          // Фабрика для создания кластерных аннотаций
          const factory = (coordinate: any, options: { data: { count: string | null; }; }) => {
            const div = document.createElement('div');
            div.textContent = options.data.count;
            div.className = 'custom-cluster';
            return div;
          };
      
          this.map.annotationForCluster = (cluster: { memberAnnotations: any[]; coordinate: any; }) => {
            console.log('Создание аннотации кластера для:', cluster);
            const options = { data: { count: cluster.memberAnnotations.length } };
            const clusterAnnotation = new window.mapkit.Annotation(cluster.coordinate, factory, options);
            return clusterAnnotation;
          };
      
          console.log('Карта успешно создана');
        } else {
          // Если карта уже создана, убедимся, что её DOM-элемент привязан к новому контейнеру
          if (this.map.element && container !== this.map.element.parentElement) {
            // Если элемент карты находится в другом родительском элементе – перемещаем его
            if (this.map.element.parentElement) {
              this.map.element.parentElement.removeChild(this.map.element);
            }
            container.appendChild(this.map.element);
            console.log('Существующая карта перемещена в новый контейнер');
          } else {
            console.log('Карта уже существует, используем существующий экземпляр');
          }
        }
      },
      
    
    addAnnotations(): void {
      if (!this.map) {
        console.error('Карта не инициализирована');
        return;
      }

      console.log('Добавление аннотаций:', this.places);

      const annotations = this.places.map(place => {
        const coordinate = new window.mapkit.Coordinate(place.latitude, place.longitude);
        const annotation = new window.mapkit.ImageAnnotation(coordinate, {
          url: {1: '/assets/single-pin.png'},
          title: place.name,
          subtitle: place.category,
          clusteringIdentifier: 'placeCluster',
          clusterRadius: 50,
        });
        return annotation;
      });

      this.map.removeAnnotations(this.map.annotations);
      this.map.addAnnotations(annotations);
      setTimeout(() => {
        this.map.showItems(annotations);
      }, 0);
    },
    
    async fetchPlaces(telegramId: string): Promise<void> {
      this.isLoading = true;
      this.error = null;
      
      try {
        console.log('Получение мест для telegram ID:', telegramId);
        const response = await axios.post(`/api/user-places`, {
          telegram_id: telegramId,
        });
        
        this.places = response.data.places;

        // Форматирование даты
        this.places.forEach(place => {
          const date = new Date(place.last_checkin);
          const options = { day: '2-digit', month: 'long' } as const;
          const userLang = localStorage.getItem('user_language');
          place.last_checkin = userLang === 'ru'
            ? date.toLocaleDateString('ru-RU', options)
            : date.toLocaleDateString('en-US', options);
        });

        this.addAnnotations();
      } catch (error) {
        console.error('Ошибка при получении мест:', error);
        this.error = 'Не удалось загрузить места';
      } finally {
        this.isLoading = false;
      }
    },
    
    getImageSrc(category: string): string {
      return require(`@/assets/${category.toLowerCase()}.png`);
    },
    
    setupMapInteractions(mapElementId: string): void {
      // Перехватываем и останавливаем события прокрутки
      const mapElement = document.getElementById(mapElementId);
      if (mapElement) {
        mapElement.addEventListener('touchstart', (e) => {
          e.stopPropagation();
          this.preventCollapse();
        });
        mapElement.addEventListener('touchmove', (e) => {
          e.stopPropagation();
        });
        mapElement.addEventListener('touchend', (e) => {
          e.stopPropagation();
        });
      }

      if (window.Telegram && window.Telegram.WebApp) {
        window.Telegram.WebApp.disableVerticalSwipes();
      }

      // Убедимся, что документ прокручивается
      this.ensureDocumentIsScrollable();
      window.addEventListener('load', this.ensureDocumentIsScrollable);
    },
    
    ensureDocumentIsScrollable(): void {
      const isScrollable = document.documentElement.scrollHeight > window.innerHeight;
      if (!isScrollable) {
        document.documentElement.style.setProperty('height', 'calc(100vh + 1px)', 'important');
      }
    },
    
    preventCollapse(): void {
      if (window.scrollY === 0) {
        window.scrollTo(0, 1);
      }
    }
  }
});