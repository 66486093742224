import { defineStore } from 'pinia';
import { ref, Ref } from 'vue';

interface Geolocation {
  latitude: number;
  longitude: number;
}

interface LocationData {
  latitude: number;
  longitude: number;
  altitude?: number | null;
  course?: number | null;
  speed?: number | null;
  horizontal_accuracy?: number | null;
  vertical_accuracy?: number | null;
  course_accuracy?: number | null;
  speed_accuracy?: number | null;
}

export const useGeolocationStore = defineStore('geolocation', () => {
  const location: Ref<Geolocation> = ref({ latitude: 55.751244, longitude: 37.618423 }); // Дефолтные координаты
  const isLoading: Ref<boolean> = ref(false);
  const error: Ref<string | null> = ref(null);
  const usingDefaultLocation: Ref<boolean> = ref(true); // По умолчанию используем дефолтные координаты
  const locationSource: Ref<string> = ref('default'); // Отслеживаем источник геопозиции
  
  const fetchLocation = async (): Promise<Geolocation> => {
    isLoading.value = true;
    error.value = null;
    locationSource.value = 'default';
    
    try {
      // Проверяем поддерживается ли LocationManager в этой версии Telegram
      const telegramApp = window.Telegram?.WebApp;
      const locationManager = telegramApp?.LocationManager;
      
      // Проверяем поддержку LocationManager и его инициализацию
      if (locationManager && typeof locationManager.getLocation === 'function') {
        try {
          // Инициализируем LocationManager, если не инициализирован
          if (!locationManager.isInited) {
            await new Promise<void>((resolve, reject) => {
              locationManager.init((err?: any) => {
                if (err) reject(err);
                else resolve();
              });
            });
          }
          
          // После инициализации проверяем доступность геолокации
          if (locationManager.isLocationAvailable) {
            const locationData: LocationData = await new Promise<LocationData>((resolve, reject) => {
              locationManager.getLocation((data: LocationData | null) => {
                if (data && data.latitude && data.longitude) {
                  resolve(data);
                } else {
                  reject(new Error('Не удалось получить геопозицию через Telegram'));
                }
              });
            });
            
            const geo: Geolocation = {
              latitude: locationData.latitude,
              longitude: locationData.longitude
            };
            
            // Устанавливаем полученные данные
            location.value = geo;
            usingDefaultLocation.value = false;
            locationSource.value = 'telegram';
            
            console.log('Успешно получены данные от Telegram:', geo);
            
            // Возвращаем полученные данные
            return geo;
          } else {
            throw new Error('Геолокация недоступна в Telegram');
          }
        } catch (err) {
          console.error('Ошибка при использовании Telegram LocationManager:', err);
          throw err;
        }
      } else {
        throw new Error('Telegram LocationManager недоступен');
      }
    } catch (err: any) {
      console.error('Ошибка получения геопозиции:', err);
      
      error.value = err.message || 'Не удалось определить местоположение';
      usingDefaultLocation.value = true;
      locationSource.value = 'default';
      
      // Возвращаем дефолтные координаты
      return location.value;
    } finally {
      isLoading.value = false;
    }
  };

  return { 
    location, 
    isLoading, 
    error, 
    usingDefaultLocation,
    locationSource, 
    fetchLocation 
  };
});