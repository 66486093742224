<template>
  <div class="container">
    <div>
      <TonConnectButton />
    </div>
    <div class="boost-page">
      <p class="title">{{ $t('boost') }}</p>
      <div class="user-coins">
        <p class="coins-title">{{ $t('your_balance') }}</p>
        <img class="coin" :src="require('@/assets/leagueCoin.png')" alt="Coin" />
        <p class="coins">{{ boostsStore.coins }}</p>
      </div>
    </div>
    <div class="boost-container">
      <div class="boosts">
        <BoostComponent 
          v-for="boost in boostsStore.boosts" 
          :key="boost.id"
          :boost="boost"
          :nextLevelPrice="boostsStore.calculateNextLevelPrice(boost.first_level_price, boost.currentLevel)"
          @open-modal="boostsStore.openModal(boost)"
        />
      </div>
    </div>
    <div class="tasks-component">
      <p class="tasks-component-text">SPECIAL MISSIONS</p>
      <div class="tasks">
        <TaskComponent
          v-for="task in tasksStore.tasks"
          :key="task.id"
          :task="task"
        />
      </div>
    </div>

    <BoostModal 
      v-if="boostsStore.selectedBoost" 
      :boost="boostsStore.selectedBoost" 
      :is-visible="boostsStore.isModalVisible"
      :next-level-price="boostsStore.calculateNextLevelPrice(boostsStore.selectedBoost.first_level_price, boostsStore.selectedBoost.currentLevel)"
      @close-modal="boostsStore.closeModal"
      @buy-boost="boostsStore.handleBuyBoost(boostsStore.selectedBoost)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';
import BoostComponent from '@/components/BoostComponent.vue';
import BoostModal from '@/components/BoostModal.vue';
import TaskComponent from '@/components/TaskComponent.vue';
import TonConnectButton from '@/components/TonConnectButton.vue';
import { useBoostsStore } from '@/store/boostsStore';
import { useTasksStore } from '@/store/tasksStore';

export default defineComponent({
  name: 'BoostPage',
  components: {
    BoostComponent,
    BoostModal,
    TaskComponent,
    TonConnectButton
  },
  setup() {
    const router = useRouter();
    const boostsStore = useBoostsStore();
    const tasksStore = useTasksStore();

    const goToMain = () => {
      router.push({ name: 'MainPage' });
    };

    onMounted(async () => {
      await boostsStore.fetchBoosts();
      await tasksStore.fetchTasks();

      window.Telegram.WebApp.ready();

      // Показываем кнопку "Назад"
      window.Telegram.WebApp.BackButton.show();

      // Обрабатываем клик по кнопке "Назад"
      window.Telegram.WebApp.BackButton.onClick(() => {
        router.push('/discovery');  
      });
    });

    return {
      boostsStore,
      tasksStore,
      goToMain
    };
  }
});
</script>

<style scoped>

.go-back {
    position: absolute;
    left: 0;
    width: 30px;
    margin-left: 10px;
    margin-top: 10px;
  }

#ton-connect-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  height: auto;
  overflow-x: hidden;
}

.tasks-component-text {
  font-size: 13px;
  font-weight: 400;
  color: var(--Native-section_header_text_color, #AAAAAA);
  margin-left: 32px;
}

.coins-title {
  font-size: 17px;
  font-weight: 400;
  color: var(--Native-hint_color, #AAAAAA);
}

  .user-coins {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
  }

  .coin {
    width: 20px;
  }

  .coins {
    font-size: 17px;
    font-weight: 400;
    margin: 0;
    color: aliceblue;
  }

  .boost-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 30px 60px;
    padding-top: 55px; 
  }

  .title {
    font-size: 34px;
    font-weight: 400;
    margin: 0px;
    color: var(--Native-text_color, #FFFFFF);

  }

  .tasks-component {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 80px;
  }

  .boost-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .boost-title {
    font-size: 20px;
    font-weight: bold;
    margin: 10px;
    color: aliceblue;
  }

  .boosts, .tasks {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  </style>
  