import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/coin.png'
import _imports_1 from '@/assets/mayor-count.png'
import _imports_2 from '@/assets/champion-cup.png'
import _imports_3 from '@/assets/stroke.png'


const _hoisted_1 = {
  key: 0,
  class: "waiting-onboarding"
}
const _hoisted_2 = {
  key: 1,
  class: "container"
}
const _hoisted_3 = { class: "user-info" }
const _hoisted_4 = { class: "user-header" }
const _hoisted_5 = { class: "left-side" }
const _hoisted_6 = { class: "points-container" }
const _hoisted_7 = { class: "points" }
const _hoisted_8 = { class: "mayor-count-container" }
const _hoisted_9 = { class: "mayor-count" }
const _hoisted_10 = { class: "user-league" }
const _hoisted_11 = { class: "user-place" }
const _hoisted_12 = { id: "map" }
const _hoisted_13 = ["src"]
const _hoisted_14 = { class: "navigation" }
const _hoisted_15 = ["src"]
const _hoisted_16 = {
  key: 0,
  class: "add-marker"
}
const _hoisted_17 = ["src"]
const _hoisted_18 = { class: "button-text" }
const _hoisted_19 = {
  key: 1,
  class: "pin-actions"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DiscoveryModal = _resolveComponent("DiscoveryModal")!
  const _component_CheckinComponent = _resolveComponent("CheckinComponent")!

  return (_ctx.waitingForOnboarding == true)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, _cache[6] || (_cache[6] = [
        _createElementVNode("div", { class: "loading-ring" }, null, -1)
      ])))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _cache[8] || (_cache[8] = _createElementVNode("img", {
                  class: "coin",
                  src: _imports_0,
                  alt: "Coin"
                }, null, -1)),
                _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.formattedPoints), 1),
                _createElementVNode("div", _hoisted_8, [
                  _cache[7] || (_cache[7] = _createElementVNode("img", {
                    class: "mayor-count-img",
                    src: _imports_1,
                    alt: "Coin"
                  }, null, -1)),
                  _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.mayorCount), 1)
                ])
              ])
            ]),
            _createElementVNode("div", {
              class: "right-side",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToLeaguePage && _ctx.redirectToLeaguePage(...args)))
            }, [
              _createElementVNode("div", _hoisted_10, [
                _cache[9] || (_cache[9] = _createElementVNode("img", {
                  class: "champion-cup",
                  src: _imports_2,
                  alt: "Champion cup"
                }, null, -1)),
                _createTextVNode(" " + _toDisplayString(_ctx.getLocalizedLeague) + " ", 1),
                _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.formatPlace(_ctx.place)), 1),
                _cache[10] || (_cache[10] = _createElementVNode("img", {
                  class: "stroke-img",
                  src: _imports_3,
                  alt: "stroke"
                }, null, -1))
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_12, [
          (_ctx.addingPin)
            ? (_openBlock(), _createElementBlock("img", {
                key: 0,
                class: "center-pin",
                src: require(`@/assets/discovery/add-custom-marker.png`)
              }, null, 8, _hoisted_13))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", {
            class: "user-center",
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.centerMapOnUser && _ctx.centerMapOnUser(...args)))
          }, [
            _createElementVNode("img", {
              class: "center",
              src: require(`@/assets/center.png`)
            }, null, 8, _hoisted_15)
          ]),
          (!_ctx.addingPin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                _createElementVNode("button", {
                  class: "add-marker-button",
                  onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startAddingPin && _ctx.startAddingPin(...args)))
                }, [
                  _createElementVNode("img", {
                    class: "marker",
                    src: require(`@/assets/marker.png`)
                  }, null, 8, _hoisted_17),
                  _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('addpin')), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          (_ctx.addingPin)
            ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                _createElementVNode("button", {
                  class: "cancel-button",
                  onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.cancelAddingPin && _ctx.cancelAddingPin(...args)))
                }, _toDisplayString(_ctx.$t('cancel')), 1),
                _createElementVNode("button", {
                  class: "next-button",
                  onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.openModal && _ctx.openModal(...args)))
                }, _toDisplayString(_ctx.$t('next')), 1)
              ]))
            : _createCommentVNode("", true)
        ]),
        _createVNode(_component_DiscoveryModal, {
          "is-visible": _ctx.isModalVisible,
          latitude: _ctx.pinLocation?.latitude,
          longitude: _ctx.pinLocation?.longitude,
          onCloseModal: _ctx.closeModal
        }, null, 8, ["is-visible", "latitude", "longitude", "onCloseModal"]),
        _createVNode(_component_CheckinComponent, {
          isVisible: _ctx.isCheckinVisible,
          "onUpdate:isVisible": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.isCheckinVisible) = $event)),
          onUpdatePlaces: _ctx.handleUpdatePlaces
        }, null, 8, ["isVisible", "onUpdatePlaces"])
      ]))
}